export const COUNTRY_LIST = [
  { code: "4", name: "Afghanistan" },
  { code: "921", name: "Akrotiri and Dhekelia" },
  { code: "248", name: "Åland" },
  { code: "8", name: "Albania" },
  { code: "12", name: "Algeria" },
  { code: "16", name: "American Samoa" },
  { code: "20", name: "Andorra" },
  { code: "24", name: "Angola" },
  { code: "660", name: "Anguilla" },
  { code: "10", name: "Antarctica" },
  { code: "28", name: "Antigua and Barbuda" },
  { code: "32", name: "Argentina" },
  { code: "51", name: "Armenia" },
  { code: "533", name: "Aruba" },
  { code: "36", name: "Australia" },
  { code: "40", name: "Austria" },
  { code: "31", name: "Azerbaijan" },
  { code: "44", name: "Bahamas" },
  { code: "48", name: "Bahrain" },
  { code: "50", name: "Bangladesh" },
  { code: "52", name: "Barbados" },
  { code: "112", name: "Belarus" },
  { code: "56", name: "Belgium" },
  { code: "84", name: "Belize" },
  { code: "204", name: "Benin" },
  { code: "60", name: "Bermuda" },
  { code: "64", name: "Bhutan" },
  { code: "68", name: "Bolivia" },
  { code: "70", name: "Bosnia and Herzegovina" },
  { code: "72", name: "Botswana" },
  { code: "74", name: "Bouvet Island" },
  { code: "76", name: "Brazil" },
  { code: "86", name: "British Indian Ocean Territory" },
  { code: "92", name: "British Virgin Islands" },
  { code: "96", name: "Brunei" },
  { code: "100", name: "Bulgaria" },
  { code: "854", name: "Burkina Faso" },
  { code: "108", name: "Burundi" },
  { code: "132", name: "Cabo Verde" },
  { code: "116", name: "Cambodia" },
  { code: "120", name: "Cameroon" },
  { code: "124", name: "Canada" },
  { code: "999", name: "Caribbean Islands" },
  { code: "530", name: "Caribbean Netherland" },
  { code: "922", name: "Caspian Sea" },
  { code: "136", name: "Cayman Islands" },
  { code: "140", name: "Central African Republic" },
  { code: "148", name: "Chad" },
  { code: "152", name: "Chile" },
  { code: "156", name: "China" },
  { code: "162", name: "Christmas Island" },
  { code: "923", name: "Clipperton Island" },
  { code: "166", name: "Cocos Islands" },
  { code: "170", name: "Colombia" },
  { code: "174", name: "Comoros" },
  { code: "184", name: "Cook Islands" },
  { code: "188", name: "Costa Rica" },
  { code: "384", name: "Côte d'Ivoire" },
  { code: "191", name: "Croatia" },
  { code: "192", name: "Cuba" },
  { code: "531", name: "Curacao" },
  { code: "196", name: "Cyprus" },
  { code: "203", name: "Czechia" },
  { code: "180", name: "Democratic Republic of the Congo" },
  { code: "208", name: "Denmark" },
  { code: "262", name: "Djibouti" },
  { code: "212", name: "Dominica" },
  { code: "214", name: "Dominican Republic" },
  { code: "218", name: "Ecuador" },
  { code: "818", name: "Egypt" },
  { code: "222", name: "El Salvador" },
  { code: "226", name: "Equatorial Guinea" },
  { code: "232", name: "Eritrea" },
  { code: "233", name: "Estonia" },
  { code: "231", name: "Ethiopia" },
  { code: "238", name: "Falkland Islands" },
  { code: "234", name: "Faroe Islands" },
  { code: "242", name: "Fiji" },
  { code: "246", name: "Finland" },
  { code: "250", name: "France" },
  { code: "254", name: "French Guiana" },
  { code: "258", name: "French Polynesia" },
  { code: "260", name: "French Southern Territories" },
  { code: "266", name: "Gabon" },
  { code: "270", name: "Gambia" },
  { code: "268", name: "Georgia" },
  { code: "276", name: "Germany" },
  { code: "288", name: "Ghana" },
  { code: "292", name: "Gibraltar" },
  { code: "300", name: "Greece" },
  { code: "304", name: "Greenland" },
  { code: "308", name: "Grenada" },
  { code: "312", name: "Guadeloupe" },
  { code: "316", name: "Guam" },
  { code: "320", name: "Guatemala" },
  { code: "831", name: "Guernsey" },
  { code: "324", name: "Guinea" },
  { code: "624", name: "Guinea-Bissau" },
  { code: "328", name: "Guyana" },
  { code: "332", name: "Haiti" },
  { code: "334", name: "Heard Island and McDonald Island" },
  { code: "340", name: "Honduras" },
  { code: "348", name: "Hungary" },
  { code: "352", name: "Iceland" },
  { code: "356", name: "India" },
  { code: "360", name: "Indonesia" },
  { code: "364", name: "Iran" },
  { code: "368", name: "Iraq" },
  { code: "372", name: "Ireland" },
  { code: "833", name: "Isle of Man" },
  { code: "376", name: "Israel" },
  { code: "380", name: "Italy" },
  { code: "388", name: "Jamaica" },
  { code: "392", name: "Japan" },
  { code: "832", name: "Jersey" },
  { code: "400", name: "Jordan" },
  { code: "398", name: "Kazakhstan" },
  { code: "404", name: "Kenya" },
  { code: "296", name: "Kiribati" },
  { code: "383", name: "Kosovo" },
  { code: "414", name: "Kuwait" },
  { code: "417", name: "Kyrgyzstan" },
  { code: "418", name: "Laos" },
  { code: "428", name: "Latvia" },
  { code: "422", name: "Lebanon" },
  { code: "426", name: "Lesotho" },
  { code: "430", name: "Liberia" },
  { code: "434", name: "Libya" },
  { code: "438", name: "Liechtenstein" },
  { code: "440", name: "Lithuania" },
  { code: "442", name: "Luxembourg" },
  { code: "450", name: "Madagascar" },
  { code: "454", name: "Malawi" },
  { code: "458", name: "Malaysia" },
  { code: "462", name: "Maldives" },
  { code: "466", name: "Mali" },
  { code: "470", name: "Malta" },
  { code: "584", name: "Marshall Islands" },
  { code: "474", name: "Martinique" },
  { code: "478", name: "Mauritania" },
  { code: "480", name: "Mauritius" },
  { code: "175", name: "Mayotte" },
  { code: "484", name: "México" },
  { code: "583", name: "Micronesia" },
  { code: "498", name: "Moldova" },
  { code: "492", name: "Monaco" },
  { code: "496", name: "Mongolia" },
  { code: "499", name: "Montenegro" },
  { code: "500", name: "Montserrat" },
  { code: "504", name: "Morocco" },
  { code: "508", name: "Mozambique" },
  { code: "104", name: "Myanmar" },
  { code: "516", name: "Namibia" },
  { code: "520", name: "Nauru" },
  { code: "524", name: "Nepal" },
  { code: "528", name: "Netherlands" },
  { code: "540", name: "New Caledonia" },
  { code: "554", name: "New Zealand" },
  { code: "558", name: "Nicaragua" },
  { code: "562", name: "Niger" },
  { code: "566", name: "Nigeria" },
  { code: "570", name: "Niue" },
  { code: "574", name: "Norfolk Island" },
  { code: "408", name: "North Korea" },
  { code: "807", name: "North Macedonia" },
  { code: "928", name: "Northern Cyprus" },
  { code: "580", name: "Northern Mariana Islands" },
  { code: "578", name: "Norway" },
  { code: "512", name: "Oman" },
  { code: "586", name: "Pakistan" },
  { code: "585", name: "Palau" },
  { code: "275", name: "Palestine" },
  { code: "591", name: "Panama" },
  { code: "598", name: "Papua New Guinea" },
  { code: "925", name: "Paracel Islands" },
  { code: "600", name: "Paraguay" },
  { code: "604", name: "Peru" },
  { code: "608", name: "Philippines" },
  { code: "612", name: "Pitcairn Islands" },
  { code: "616", name: "Poland" },
  { code: "620", name: "Portugal" },
  { code: "630", name: "Puerto Rico" },
  { code: "634", name: "Qatar" },
  { code: "178", name: "Republic of the Congo" },
  { code: "638", name: "Réunion" },
  { code: "642", name: "Romania" },
  { code: "643", name: "Russia" },
  { code: "646", name: "Rwanda" },
  { code: "652", name: "Saint-Barthélemy" },
  { code: "663", name: "Saint-Martin" },
  { code: "654", name: "Saint Helena, Ascension and Tris" },
  { code: "659", name: "Saint Kitts and Nevis" },
  { code: "662", name: "Saint Lucia" },
  { code: "666", name: "Saint Pierre and Miquelon" },
  { code: "670", name: "Saint Vincent and the Grenadines" },
  { code: "882", name: "Samoa" },
  { code: "674", name: "San Marino" },
  { code: "678", name: "São Tomé and Príncipe" },
  { code: "682", name: "Saudi Arabia" },
  { code: "686", name: "Senegal" },
  { code: "688", name: "Serbia" },
  { code: "690", name: "Seychelles" },
  { code: "694", name: "Sierra Leone" },
  { code: "702", name: "Singapore" },
  { code: "534", name: "Sint Maarten" },
  { code: "703", name: "Slovakia" },
  { code: "705", name: "Slovenia" },
  { code: "90", name: "Solomon Islands" },
  { code: "706", name: "Somalia" },
  { code: "710", name: "South Africa" },
  { code: "239", name: "South Georgia and the South Sand" },
  { code: "410", name: "South Korea" },
  { code: "728", name: "South Sudan" },
  { code: "724", name: "Spain" },
  { code: "927", name: "Spratly Islands" },
  { code: "144", name: "Sri Lanka" },
  { code: "736", name: "Sudan" },
  { code: "740", name: "Suriname" },
  { code: "744", name: "Svalbard and Jan Mayen" },
  { code: "748", name: "Swaziland" },
  { code: "752", name: "Sweden" },
  { code: "756", name: "Switzerland" },
  { code: "760", name: "Syria" },
  { code: "158", name: "Taiwan" },
  { code: "762", name: "Tajikistan" },
  { code: "834", name: "Tanzania" },
  { code: "764", name: "Thailand" },
  { code: "626", name: "Timor-Leste" },
  { code: "768", name: "Togo" },
  { code: "772", name: "Tokelau" },
  { code: "776", name: "Tonga" },
  { code: "780", name: "Trinidad and Tobago" },
  { code: "788", name: "Tunisia" },
  { code: "792", name: "Turkey" },
  { code: "795", name: "Turkmenistan" },
  { code: "796", name: "Turks and Caicos Islands" },
  { code: "798", name: "Tuvalu" },
  { code: "800", name: "Uganda" },
  { code: "804", name: "Ukraine" },
  { code: "784", name: "United Arab Emirates" },
  { code: "826", name: "United Kingdom" },
  { code: "840", name: "United States" },
  { code: "581", name: "United States Minor Outlying Isl" },
  { code: "858", name: "Uruguay" },
  { code: "860", name: "Uzbekistan" },
  { code: "548", name: "Vanuatu" },
  { code: "336", name: "Vatican City" },
  { code: "862", name: "Venezuela" },
  { code: "704", name: "Vietnam" },
  { code: "850", name: "Virgin Islands, U.S." },
  { code: "876", name: "Wallis and Futuna" },
  { code: "732", name: "Western Sahara" },
  { code: "887", name: "Yemen" },
  { code: "894", name: "Zambia" },
  { code: "716", name: "Zimbabwe" },
];

export const INDUSTRY_FIELDS = [
  "Aerospace & Defense",
  "Agriculture",
  "Biotechnology",
  "Consulting",
  "Education",
  "Entomology",
  "Epidemiology",
  "Government & Public Sector",
  "Healthcare",
  "Information Technology",
  "Infectious Diseases",
  "Natural Science research",
  "Pharmaceuticals",
  "Professional Services",
  "Retail",
  "Social Science research",
  "Technology",
  "Telecommunications",
  "Other",
];

export const REFERRAL_SOURCES = [
  "Colleague",
  "News",
  "Report",
  "Research Reference",
  "Social Media",
  "LinkedIn",
];
