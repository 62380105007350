import { Menu } from "antd";
import {
  BarChartOutlined,
  DashboardOutlined,
  FundProjectionScreenOutlined,
  SettingOutlined,
  UserOutlined,
  FileTextOutlined,
  DatabaseOutlined,
  CompassOutlined,
  YoutubeOutlined,
  FilePdfOutlined,
  BookOutlined,
} from "@ant-design/icons";
import { Layout } from "antd";
import SubMenu from "antd/lib/menu/SubMenu";
import { Link } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { selectCurrentUser } from "../../redux/user/userSelector";
import { selectIsAuthenticated } from "../../redux/auth/authSelector";
import { logout } from "../../redux/auth/authActions";
import { FormattedMessage } from "react-intl";
import { changeLanguage } from "../../utils/utilData";

const { Header } = Layout;

const REACT_APP_NAME_SHORT = process.env.REACT_APP_NAME_SHORT;

const Navbar = (props) => {
  const dispatch = useDispatch();

  const logoutHandler = () => {
    props.logout(props.history);
    props.history.push("/");
  };

  const changeLanguageHandler = (lang) => {
    localStorage.setItem("language", lang);
    dispatch(changeLanguage(props.history, lang)).then(
      window.location.reload(false)
    );
  };

  const checkPermissions = (requiredPermission) => {
    return (
      props.isAuthenticated &&
      requiredPermission &&
      props.currentUser &&
      props.currentUser?.permissions?.find(
        (permission) => permission === requiredPermission
      )
    );
  };

  return (
    <Header className="header">
      {/*<GlobalOutlined className="logo"/>*/}
      {props.isAuthenticated && props.currentUser ? (
        <>
          <Link to="/">
            <div
              style={{ display: "flex", margin: "auto 10px", float: "left" }}
            >
              <img
                src="/configurable/contents/images/logo.png"
                alt=""
                className="img-logo"
                style={{ width: "60px", height: "60px" }}
              />
              <h3 className="logo" style={{ margin: "auto 0 auto 10px" }}>
                {REACT_APP_NAME_SHORT}
              </h3>
            </div>
          </Link>
          <Menu
            theme="dark"
            mode="horizontal"
            style={{ display: "flex", justifyContent: "flex-end" }}
            defaultSelectedKeys={["/dashboard"]}
            selectedKeys={[props.location.pathname]}
          >
            {checkPermissions("ROLE_PERMISSION_VIEW_DASHBOARD") ? (
              <Menu.Item key="/dashboard" icon={<DashboardOutlined />}>
                <Link to="/dashboard">
                  <FormattedMessage
                    id="menu.dashboard"
                    defaultMessage="Dashboard"
                  />
                </Link>
              </Menu.Item>
            ) : (
              <></>
            )}

            {checkPermissions("ROLE_PERMISSION_ADMIN_USER_VIEW") ? (
              <SubMenu
                key="/admin"
                icon={<UserOutlined />}
                title={
                  <FormattedMessage id="menu.admin" defaultMessage="Admin" />
                }
              >
                <Menu.Item key="/admin/users">
                  <Link to="/admin/users">
                    <FormattedMessage id="menu.users" defaultMessage="Users" />
                  </Link>
                </Menu.Item>
                {checkPermissions("ROLE_ADMIN") ? (
                  <>
                    <Menu.Item key="/admin/roles">
                      <Link to="/admin/roles">
                        <FormattedMessage
                          id="menu.roles"
                          defaultMessage="Roles"
                        />
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="/admin/permissions">
                      <Link to="/admin/permissions">
                        <FormattedMessage
                          id="menu.permissions"
                          defaultMessage="Permissions"
                        />
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="/admin/jobs">
                      <Link to="/admin/jobs">
                        <FormattedMessage
                          id="label.jobs"
                          defaultMessage="Jobs"
                        />
                      </Link>
                    </Menu.Item>
                  </>
                ) : (
                  <></>
                )}
              </SubMenu>
            ) : (
              <></>
            )}
            {checkPermissions("ROLE_ADMIN") ? (
              <SubMenu
                key="/configuration"
                icon={<SettingOutlined />}
                title={
                  <FormattedMessage
                    id="menu.configuration"
                    defaultMessage="Configuration"
                  />
                }
              >
                <Menu.Item key="/configuration/alerts">
                  <Link to="/configuration/alerts">
                    <FormattedMessage
                      id="label.alerts"
                      defaultMessage="Alerts"
                    />
                  </Link>
                </Menu.Item>
                <Menu.Item key="/configuration/dataAnalysis">
                  <Link to="/configuration/dataAnalysis">
                    <FormattedMessage
                      id="label.analysis"
                      defaultMessage="Analysis"
                    />
                  </Link>
                </Menu.Item>
                <Menu.Item key="/configuration/apps">
                  <Link to="/configuration/apps">
                    <FormattedMessage id="label.apps" defaultMessage="APPs" />
                  </Link>
                </Menu.Item>
                <Menu.Item key="/configuration/css">
                  <Link to="/configuration/css">
                    <FormattedMessage id="label.css" defaultMessage="CSS" />
                  </Link>
                </Menu.Item>
                <Menu.Item key="/configuration/equations">
                  <Link to="/configuration/equations">
                    <FormattedMessage
                      id="label.equations"
                      defaultMessage="Equations"
                    />
                  </Link>
                </Menu.Item>
                <Menu.Item key="/configuration/paths">
                  <Link to="/configuration/paths">
                    <FormattedMessage id="menu.paths" defaultMessage="Paths" />
                  </Link>
                </Menu.Item>
                <Menu.Item key="/configuration/reports">
                  <Link to={"/configuration/reports"}>
                    <FormattedMessage
                      id="menu.reports"
                      defaultMessage="Reports"
                    />
                  </Link>
                </Menu.Item>
                <Menu.Item key="/configuration/seo">
                  <Link to="/configuration/seo">
                    <FormattedMessage id="label.seo" defaultMessage="SEO" />
                  </Link>
                </Menu.Item>
                <Menu.Item key="/configuration/datasources">
                  <Link to="/configuration/datasources">
                    <FormattedMessage
                      id="menu.configuration.datasources"
                      defaultMessage="Data Sources"
                    />
                  </Link>
                </Menu.Item>
              </SubMenu>
            ) : (
              <></>
            )}
            {checkPermissions("ROLE_PERMISSION_PROJECT_VIEW") ? (
              <SubMenu
                key="/project"
                icon={<FundProjectionScreenOutlined />}
                title={
                  <FormattedMessage
                    id="menu.projects"
                    defaultMessage="Projects"
                  />
                }
              >
                {checkPermissions("ROLE_PERMISSION_PROJECT_VIEW") ? (
                  <Menu.Item key="/projects">
                    <Link to="/projects">
                      <FormattedMessage
                        id="label.projects-list"
                        defaultMessage="Projects List"
                      />
                    </Link>
                  </Menu.Item>
                ) : (
                  <></>
                )}
                {checkPermissions("ROLE_PERMISSION_STAFF_VIEW") ? (
                  <Menu.Item key="/staff">
                    <Link to="/staff">
                      <FormattedMessage
                        id="label.staff-members"
                        defaultMessage="Staff Members"
                      />
                    </Link>
                  </Menu.Item>
                ) : (
                  <></>
                )}
                {checkPermissions("ROLE_PERMISSION_ALERT_CONTACT_VIEW") ? (
                  <Menu.Item key="/contacts">
                    <Link to="/contacts">
                      <FormattedMessage
                        id="label.contacts"
                        defaultMessage="Contacts"
                      />
                    </Link>
                  </Menu.Item>
                ) : (
                  <></>
                )}
                {checkPermissions("ROLE_PERMISSION_CONFIG_FORM") ? (
                  <>
                    <Menu.Item key="/forms">
                      <Link to="/forms">
                        <FormattedMessage
                          id="menu.forms"
                          defaultMessage="Forms"
                        />
                      </Link>
                    </Menu.Item>
                  </>
                ) : (
                  <></>
                )}
                {checkPermissions("ROLE_PERMISSION_CONFIG_FORM_TEMPLATE") ? (
                  <>
                    <Menu.Item key="/form-templates/templates">
                      <Link to="/form-templates/templates">
                        <FormattedMessage
                          id="label.formTemplates.configuration"
                          defaultMessage="Form Templates"
                        />
                      </Link>
                    </Menu.Item>
                  </>
                ) : (
                  <></>
                )}
                {checkPermissions("ROLE_PERMISSION_LAYER_EDIT") ? (
                  <SubMenu
                    key="/layers"
                    title={
                      <FormattedMessage
                        id="menu.layers"
                        defaultMessage="Layers"
                      />
                    }
                  >
                    {checkPermissions("ROLE_PERMISSION_LAYER_EDIT") ? (
                      <Menu.Item key="/points-of-interest">
                        <Link to="/layers/points-of-interest">
                          <FormattedMessage
                            id="label.points-of-interest"
                            defaultMessage="Points of Interest"
                          />
                        </Link>
                      </Menu.Item>
                    ) : (
                      <></>
                    )}
                  </SubMenu>
                ) : (
                  <></>
                )}
                {checkPermissions("ROLE_PERMISSION_ALERT_CONTACT_VIEW") ? (
                  <Menu.Item key="/subscriptions">
                    <Link to="/subscriptions">
                      <FormattedMessage
                        id="menu.subscriptions"
                        defaultMessage="Subscriptions"
                      />
                    </Link>
                  </Menu.Item>
                ) : (
                  <></>
                )}
              </SubMenu>
            ) : (
              <></>
            )}
            {checkPermissions("ROLE_PERMISSION_DATA_VIEW") ? (
              <SubMenu
                key="/data"
                icon={<DatabaseOutlined />}
                title={
                  <FormattedMessage id="label.data" defaultMessage="Data" />
                }
              >
                <Menu.Item key="/my-query-form">
                  <Link to="/my-query-form">
                    <FormattedMessage
                      id="label.my-query-form"
                      defaultMessage="MyQueryForm"
                    />
                  </Link>
                </Menu.Item>
                {checkPermissions("ROLE_ADMIN") ? (
                  <Menu.Item key="/mosquito-tracker">
                    <Link to="/mosquito-tracker">
                      <FormattedMessage
                        id="label.mosquito-tracker"
                        defaultMessage="MosquitoTracker"
                      />
                    </Link>
                  </Menu.Item>
                ) : (
                  <></>
                )}
                {/*{checkPermissions('ROLE_PERMISSION_DATA_VIEW') ?*/}
                {/*    <Menu.Item key="/weather"><Link to='/weather'>*/}
                {/*        <FormattedMessage id="label.weather" defaultMessage="Weather"/>*/}
                {/*    </Link></Menu.Item> : <></>}*/}
                {checkPermissions("ROLE_ADMIN") ? (
                  <Menu.Item key="/census">
                    <Link to="/census">
                      <FormattedMessage
                        id="label.census"
                        defaultMessage="Census"
                      />
                    </Link>
                  </Menu.Item>
                ) : (
                  <></>
                )}
              </SubMenu>
            ) : (
              <></>
            )}
            {checkPermissions("ROLE_PERMISSION_DATA_ANALYSIS_VIEW") && (
              <>
                <SubMenu
                  key="/analysis"
                  icon={<BarChartOutlined />}
                  title={
                    <FormattedMessage
                      id="label.analysis"
                      defaultMessage="Analysis"
                    />
                  }
                >
                  {(checkPermissions("ROLE_PERMISSION_ANALYSIS_BAR_PLOTS") ||
                    checkPermissions("ROLE_PERMISSION_ANALYSIS_TSERIES")) && (
                    <SubMenu
                      key="/statistics"
                      icon={<BarChartOutlined />}
                      title={
                        <FormattedMessage
                          id="label.statistics"
                          defaultMessage="Statistics"
                        />
                      }
                    >
                      {checkPermissions(
                        "ROLE_PERMISSION_ANALYSIS_BAR_PLOTS"
                      ) && (
                        <Menu.Item key="/analysis/bar-plots">
                          <Link to="/analysis/bar-plots">
                            <FormattedMessage
                              id="label.bar-plots"
                              defaultMessage="Bar Plots"
                            />
                          </Link>
                        </Menu.Item>
                      )}
                      {checkPermissions("ROLE_PERMISSION_ANALYSIS_TSERIES") && (
                        <Menu.Item key="/analysis/time-series">
                          <Link to="/analysis/time-series">
                            <FormattedMessage
                              id="label.time-series"
                              defaultMessage="Time Series"
                            />
                          </Link>
                        </Menu.Item>
                      )}
                    </SubMenu>
                  )}
                  {(checkPermissions("ROLE_PERMISSION_ANALYSIS_MAP_POINT") ||
                    checkPermissions("ROLE_PERMISSION_ANALYSIS_MAP_HEAT") ||
                    checkPermissions("ROLE_PERMISSION_ANALYSIS_MAP_BUBBLE") ||
                    checkPermissions(
                      "ROLE_PERMISSION_ANALYSIS_MAP_CHOROPLETH"
                    )) && (
                    <SubMenu
                      key="/maps"
                      icon={<CompassOutlined />}
                      title={
                        <FormattedMessage
                          id="label.maps"
                          defaultMessage="Maps"
                        />
                      }
                    >
                      {checkPermissions(
                        "ROLE_PERMISSION_ANALYSIS_MAP_POINT"
                      ) && (
                        <Menu.Item key="/analysis/point-maps">
                          <Link to="/analysis/point-maps">
                            <FormattedMessage
                              id="label.point-maps"
                              defaultMessage="Point Maps"
                            />
                          </Link>
                        </Menu.Item>
                      )}
                      {checkPermissions(
                        "ROLE_PERMISSION_ANALYSIS_MAP_HEAT"
                      ) && (
                        <Menu.Item key="/analysis/heat-maps">
                          <Link to="/analysis/heat-maps">
                            <FormattedMessage
                              id="label.heat-maps"
                              defaultMessage="Heat Maps"
                            />
                          </Link>
                        </Menu.Item>
                      )}
                      {checkPermissions(
                        "ROLE_PERMISSION_ANALYSIS_MAP_CHOROPLETH"
                      ) && (
                        <Menu.Item key="/analysis/choropleth-maps">
                          <Link to="/analysis/choropleth-maps">
                            <FormattedMessage
                              id="label.choropleth-maps"
                              defaultMessage="Choropleth Maps"
                            />
                          </Link>
                        </Menu.Item>
                      )}
                      {checkPermissions(
                        "ROLE_PERMISSION_ANALYSIS_MAP_BUBBLE"
                      ) && (
                        <Menu.Item key="/analysis/bubble-maps">
                          <Link to="/analysis/bubble-maps">
                            <FormattedMessage
                              id="label.bubble-maps"
                              defaultMessage="Bubble Maps"
                            />
                          </Link>
                        </Menu.Item>
                      )}
                    </SubMenu>
                  )}
                </SubMenu>
              </>
            )}
            
            {checkPermissions("ROLE_PERMISSION_REPORT_VIEW") ? (
              <Menu.Item key="/reports" icon={<FileTextOutlined />}>
                <Link to={"/reports"}>
                  <FormattedMessage
                    id="menu.reports"
                    defaultMessage="Reports"
                  />
                </Link>
              </Menu.Item>
            ) : (
              <></>
            )}
            {checkPermissions("ROLE_PERMISSION_GUIDE_VIEW") ? (
              <SubMenu
                key="guide"
                icon={<BookOutlined />}
                title={
                  <FormattedMessage
                    id="menu.guide"
                    defaultMessage="Tutorials"
                  />
                }
              >
                <Menu.Item key="/guide" icon={<FilePdfOutlined />}>
                  <Link to={"/guide"}>
                    <FormattedMessage
                      id="menu.manual"
                      defaultMessage="Manual"
                    />
                  </Link>
                </Menu.Item>
                <Menu.Item key="/video" icon={<YoutubeOutlined />}>
                  <Link
                    to={{
                      pathname:
                        "https://www.youtube.com/playlist?list=PL4pgE2g4DCm2E09AuohQgfjAz8FR2G2uP",
                    }}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FormattedMessage id="menu.video" defaultMessage="Video" />
                  </Link>
                </Menu.Item>
              </SubMenu>
            ) : (
              <></>
            )}
            <SubMenu
              key="profile"
              icon={<UserOutlined />}
              title={props.currentUser.username}
            >
              <Menu.Item key="profile-m">
                <Link to="/profile">
                  <FormattedMessage
                    id="menu.profile"
                    defaultMessage="Profile"
                  />
                </Link>
              </Menu.Item>
              <SubMenu
                key="/languages"
                title={
                  <FormattedMessage
                    id="menu.languages"
                    defaultMessage="Languages"
                  />
                }
              >
                <Menu.Item key="en" onClick={() => changeLanguageHandler("en")}>
                  <FormattedMessage id="label.EN" defaultMessage="English" />
                </Menu.Item>
                <Menu.Item key="es" onClick={() => changeLanguageHandler("es")}>
                  <FormattedMessage id="label.ES" defaultMessage="Spanish" />
                </Menu.Item>
              </SubMenu>
              <Menu.Item key="logout" onClick={logoutHandler}>
                <FormattedMessage id="menu.logout" defaultMessage="Logout" />
              </Menu.Item>
            </SubMenu>
          </Menu>
        </>
      ) : (
        <>
          <Link to="/">
            <div
              style={{ display: "flex", margin: "auto 10px", float: "left" }}
            >
              <img
                src="/configurable/contents/images/logo.png"
                alt=""
                className="img-logo"
                style={{ width: "60px", height: "60px" }}
              />
              <h3 className="logo" style={{ margin: "auto 0 auto 10px" }}>
                {REACT_APP_NAME_SHORT}
              </h3>
            </div>
          </Link>
          <Menu
            theme="dark"
            mode="horizontal"
            style={{ display: "flex", justifyContent: "flex-end" }}
            defaultSelectedKeys={["home"]}
          >
            <Menu.Item key="home">
              <Link to="/">
                <FormattedMessage id="menu.home" defaultMessage="Home" />
              </Link>
            </Menu.Item>
            <Menu.Item key="login">
              <Link to="/login">
                <FormattedMessage id="menu.login" defaultMessage="Login" />
              </Link>
            </Menu.Item>
          </Menu>
        </>
      )}
    </Header>
  );
};

const mapStateToProps = (state) => ({
  currentUser: selectCurrentUser(state),
  isAuthenticated: selectIsAuthenticated(state),
});

export default connect(mapStateToProps, { logout })(Navbar);
