import {Breadcrumb, Layout, Row, Col, Card,} from "antd";
import {HomeOutlined} from "@ant-design/icons";
import {FormattedMessage, useIntl} from "react-intl";
// import {ResponsivePie} from '@nivo/pie'
import {/*useRef,*/ useEffect, useState} from 'react';
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl';
// import * as turf from '@turf/turf';
import './Dashboard.css';
import {selectAppLoading, selectGeoArea} from "../../redux/user/userSelector";
import {connect} from "react-redux";
import {getDashboard} from "../../utils/utilData";
import {setAppLoading, clearAppLoading} from "../../redux/user/userActions";
import Plot from 'react-plotly.js';
import moment from "moment";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_KEY;

const {Content, Footer} = Layout;

const Dashboard = (props) => {
    // const mapContainerRef = useRef();
    const intl = useIntl();
    const [loading, setLoading] = useState(false);
    const [initialLoad, setInitialLoad] = useState(true);
    const [formRecordsStats, setFormRecordsStats] = useState([]);
    // const [formPoints, setFormPoints] = useState(null);
    const [regionByUsers, setRegionByUsers] = useState([]);


    useEffect(() => {

        if (regionByUsers?.length === 0 && initialLoad) {
            setInitialLoad(false)
            setLoading(true)
            props.getDashboard(props.history).then((data) => {
                if (data) {
                    const formData = []
                    const formRecordsByDateLast15Days = data['formRecordsByDateLast15Days']
                    // setFormPoints(data['formPoints'])
                    for (let i = 0; i < formRecordsByDateLast15Days.length; i++) {
                        const seriesValue = {}
                        seriesValue['name'] = formRecordsByDateLast15Days[i]['id']
                        seriesValue['x'] = []
                        seriesValue['y'] = []
                        seriesValue['text'] = []
                        seriesValue['mode'] = 'lines+markers'
                        seriesValue['hovertemplate'] = `%{text}: %{y}<extra></extra>`
                        seriesValue['type'] = 'scatter'
                        seriesValue['line'] = {shape: 'spline'}
                        const seriesData = formRecordsByDateLast15Days[i]['data']
                        for (let j = 0; j < seriesData.length; j++) {
                            seriesValue['x'].push(moment(seriesData[j]['x']).format('MMM-DD'))
                            seriesValue['y'].push(seriesData[j]['y'])
                            seriesValue['text'].push(formRecordsByDateLast15Days[i]['id'])
                        }
                        formData.push(seriesValue)
                    }
                    setFormRecordsStats(formData)
                    const regionByUsers = data['regionByUsers']
                    const regionData = []
                    for (let key in regionByUsers) {
                        if (regionByUsers.hasOwnProperty(key)) {
                            if (regionByUsers[key] !== 0)
                                regionData.push({id: key, label: key, value: regionByUsers[key]});
                        }
                    }
                    setRegionByUsers(regionData)
                    setLoading(false)
                }
            })
        }

        // if (props.geoArea === undefined || props.geoArea === null)
        //     props.getUserGeoArea(props.history)
        // if (props.geoArea && formPoints !== null) {
        //     let {lat, lon, geojson} = props.geoArea;
        //     const map = new mapboxgl.Map({
        //         container: mapContainerRef.current,
        //         style: 'mapbox://styles/mapbox/streets-v11?optimize=true',
        //         center: [lon, lat],
        //         zoom: 2
        //     });

        //     // Add navigation control (the +/- zoom buttons)
        //     map.addControl(new mapboxgl.NavigationControl(), 'top-right');
        //     map.on('load', () => {
        //         map.addSource('region', {type: 'geojson', data: geojson});
        //         map.addLayer({
        //             'id': 'region',
        //             'type': 'line',
        //             'source': 'region',
        //             'layout': {
        //                 'visibility': 'visible'
        //             },
        //             'paint': {
        //                 'line-color': 'green',
        //                 'line-width': 2,
        //             }
        //         });
        //         const bbox = turf.bbox(geojson);
        //         map.fitBounds(bbox, {padding: 20});

        //         formPoints.forEach(point => {
        //             map.addSource(point?.id, {
        //                 'type': 'geojson',
        //                 'data': {
        //                     'type': 'FeatureCollection',
        //                     'features': [
        //                         {
        //                             'type': 'Feature',
        //                             'geometry': {
        //                                 'type': 'Point',
        //                                 'coordinates': [point?.longitude, point?.latitude]
        //                             }
        //                         }
        //                     ]
        //                 }
        //             });
        //             map.addLayer({
        //                 'id': point?.id,
        //                 'type': 'circle',
        //                 'source': point?.id,
        //                 'paint': {
        //                     'circle-color': point?.color,
        //                     'circle-radius': 6,
        //                     'circle-stroke-width': 1,
        //                     'circle-stroke-color': '#ffffff'
        //                 }
        //             });
        //         })
        //     });

        //     // Clean up on unmount
        //     return () => map.remove();
        // }
    }, [props.geoArea, loading]); // eslint-disable-line react-hooks/exhaustive-deps


    return (
        <Layout className="site-layout">
            <Content style={{margin: '0 16px'}}>
                <Breadcrumb style={{margin: '10px 0'}}>
                    <Breadcrumb.Item>
                        <HomeOutlined/>
                        <span><FormattedMessage id="menu.dashboard" defaultMessage="Dashboard"/></span>
                    </Breadcrumb.Item>

                </Breadcrumb>

                <div className="site-layout-background" style={{padding: 24, minHeight: 360}}>

                    <Row gutter={16}>
                        <Col span={24}>
                            <Card className="cardTitle" title={intl.formatMessage({id: 'dashboard.last-15-days'})}
                                  loading={loading}>
                                <Col style={{height: 500, padding: 0}}>
                                    <Plot style={{width: "100%", height: "100%"}}
                                          config={{responsive: true, displaylogo: false}}
                                          data={formRecordsStats}
                                          layout={{
                                              autosize: true,
                                              showlegend: false,
                                              hovermode: 'x unified',
                                              xaxis: {
                                                  title: {
                                                      text: intl.formatMessage({id: 'dashboard.dates'})
                                                  },
                                                  ticks: 'outside',
                                                  autorange: true,
                                                  showgrid: true,
                                                  automargin: true,
                                                  autotick: true
                                              },
                                              yaxis: {
                                                  title: {
                                                      text: intl.formatMessage({id: 'dashboard.number-records'})
                                                  },
                                                  ticks: 'outside',
                                                  autorange: true,
                                                  showgrid: true,
                                                  automargin: true,
                                                  autotick: true,
                                              }
                                          }}
                                    />
                                </Col>
                            </Card>
                        </Col>



                    </Row>

                </div>
            </Content>
            <Footer style={{textAlign: 'center'}}>©{new Date().getFullYear()}</Footer>
        </Layout>
    );
}
const mapStateToProps = state => ({
    loading: selectAppLoading(state),
    geoArea: selectGeoArea(state)
});

export default connect(mapStateToProps, {
    setAppLoading,
    clearAppLoading,
    getDashboard
})(Dashboard);
