import React from "react";

export const JSX_TEXT_ITEM_CLASS =
  "JSXItemWithPrefixesWithStyle-textItem-class";

export const getJSXItemWithStyle = (item) => {
  const grayColor = "#b3b3b3";
  return (
    <span
      key={Math.random().toString()}
      style={{
        color: grayColor,
        borderColor: grayColor,
        backgroundColor: "transparent",
        border: "solid",
        borderWidth: "1px",
        padding: "1px",
        margin: "2px 5px 2px 0",
        height: "50% !important",
      }}
    >
      {item}
    </span>
  );
};

export const getJSXItemsWithStyle = (items) => {
  return items.map((item) => getJSXItemWithStyle(item));
};

export const getJSXItemWithPrefixesWithStyle = (
  prefixedItems,
  item,
  itemStyle,
  hidePrefixes = false
) => {
  //recommended itemStyle = { fontWeight: "bold" }
  return (
    <span>
      {hidePrefixes ? null : getJSXItemsWithStyle(prefixedItems)}
      <span style={itemStyle} className={JSX_TEXT_ITEM_CLASS}>
        {item}
      </span>
    </span>
  );
};

export const getJSXItemWithPrefixesWithStyleAndLineBreak = (
  prefixedItems,
  item,
  itemStyle,
  hidePrefixes = false
) => {
  //recommended itemStyle = { fontWeight: "bold" }
  return (
    <>
      {hidePrefixes ? null : <p>{getJSXItemsWithStyle(prefixedItems)}</p>}
      <p style={itemStyle} className={JSX_TEXT_ITEM_CLASS}>
        {item}
      </p>
    </>
  );
};

export const insertElementInJSX = (
  jsx,
  textToInsert,
  position = "before",
  className = JSX_TEXT_ITEM_CLASS
) => {
  // Convert JSX to a React element
  const element = React.createElement(
    jsx.type,
    jsx.props,
    ...jsx.props.children
  );

  // Function to insert the element
  const insert = (children) => {
    return React.Children.map(children, (child) => {
      if (React.isValidElement(child) && child.props.className === className) {
        let newChildren = [];
        const jsxElementToInsert = getJSXItemWithStyle(textToInsert);
        if (position === "before") {
          newChildren.push(jsxElementToInsert);
          newChildren.push(child);
        } else if (position === "after") {
          newChildren.push(jsxElementToInsert);
        }
        return React.cloneElement(child, {}, newChildren);
      }
      return child;
    });
  };

  // Modify the children
  const modifiedChildren = insert(element.props.children);

  // Return the modified JSX element
  return React.cloneElement(element, element.props, ...modifiedChildren);
};

export const getJSXFormIdWithStyle = (formId) => {
  const grayColor = "#b3b3b3";
  return (
    <span
      style={{
        color: grayColor,
        borderColor: grayColor,
        backgroundColor: "transparent",
        border: "solid",
        borderWidth: "1px",
        padding: "1px",
        margin: "2px 5px 2px 0",
        height: "50% !important",
      }}
    >
      {formId}
    </span>
  );
};

/**
 * TreeData for dataZource form item
 * @param dataZources
 * @param intl
 * @return treeData
 */
export const getTreeDataDataZource = (dataZources, intl, hidePrefixes) => {
  let treeData = [
    {
      title: intl.formatMessage({
        id: "label.FormListDataSeries.treeData.title.forms",
      }),
      value: "forms",
      selectable: false,
      children: [],
    },
  ];
  dataZources.forEach((form) => {
    const prefixesItems = ["p" + form.project, "f" + form.id];
    const item = form.name;
    const itemStyle = undefined;
    treeData[0].children.push({
      title: getJSXItemWithPrefixesWithStyle(
        prefixesItems,
        item,
        itemStyle,
        hidePrefixes
      ),
      value: form.id,
    });
  });
  return treeData;
};

/**
 * TreeData for TreeSelect component.
 * @param selectedDataZource
 * @param formFields
 * @param view
 * @param analysisType
 * @param is_x_variable
 * @param isFilterByCategorical Used to filter by categorical variables. Default value is false.
 * @returns treeData
 */
export const getTreeDataForVariables = (
  selectedDataZource,
  formFields,
  view,
  analysisType,
  is_x_variable = false,
  isFilterByCategorical = false
) => {
  let treeData = [];

  if (!selectedDataZource || !formFields || !analysisType) {
    return treeData;
  }

  const prefixesItems = [
    "p" + selectedDataZource.project,
    "f" + selectedDataZource.id,
  ];
  const itemStyle = undefined;
  const hidePrefixes = view;
  let item;

  const titleCategoricalSuffix = " (Categorical)";

  //START - treeData - locations field-for BARPLOT
  let treeDataItemLocations = null;
  if (["BarPlot"].includes(analysisType) && is_x_variable) {
    item = "X Locations" + titleCategoricalSuffix;
    treeDataItemLocations = {
      title: getJSXItemWithPrefixesWithStyle(
        prefixesItems,
        item,
        itemStyle,
        hidePrefixes
      ),
      value: "locations",
    };
  }

  if (treeDataItemLocations !== null) {
    treeData.push(treeDataItemLocations);
  }
  // END - treeData - locations field-for BARPLOT

  //treeData - other fields
  const columnNormalizedNamesToExclude = [
    "last_updated",
    "deleted",
    !selectedDataZource?.saveUserName ? "user_name" : null,
  ].filter((item) => item !== null);

  const dataSchemas = selectedDataZource?.dataSchemas;

  const getTreeDataItem = (
    columnNormalizedName,
    index,
    dataSchema,
    formConfigurationFormFields,
    formFields,
    view,
    blockCase = "nonQB",
    isFilterByCategorical
  ) => {
    let title, value;
    //title
    const columnFormFieldType = dataSchema.columnFormFieldTypes[index];
    const columnFormFieldSqlType = formFields.find(
      (formField) => formField.type === columnFormFieldType
    )?.sqlType;
    const isCategoricalField =
      formConfigurationFormFields.find(
        (field) => field.nameNormalized === columnNormalizedName //---------
      )?.optionValues?.values?.length > 0;

    // filtering using isFilterByCategorical

    if (isFilterByCategorical && !isCategoricalField) return null;

    if (blockCase === "QB") {
      switch (columnNormalizedName) {
        case "id":
        case "date_created":
          title = view
            ? `${dataSchema.columnFormNames[index]} QB`
            : `${dataSchema.columnFormNames[index]} QB (${columnFormFieldSqlType})`;
          value = `${columnNormalizedName}QB`;
          break;
        default:
          //example of match: p_1_f_39_tcbldhpqrw_5629697856_v_0_id
          const regExp = new RegExp(
            "^p_[0-9]*_f_[0-9]*_[a-z]*_[0-9]*_v_0_id$",
            "g"
          );
          const isNonQBId = regExp.test(columnNormalizedName);
          title = view
            ? isNonQBId
              ? "Id non-QB"
              : dataSchema.columnFormNames[index]
            : isNonQBId
            ? `Id non-QB (${columnFormFieldSqlType})`
            : `${
                dataSchema.columnFormNames[index]
              } (${columnFormFieldSqlType})${
                isCategoricalField ? titleCategoricalSuffix : ""
              }`;
          value = columnNormalizedName;
      }
    } else {
      title = view
        ? dataSchema.columnFormNames[index]
        : `${dataSchema.columnFormNames[index]} (${columnFormFieldSqlType})${
            isCategoricalField || columnNormalizedName === "user_name"
              ? titleCategoricalSuffix
              : ""
          }`;
      value = columnNormalizedName;
    }

    //adding prefixes to title
    item = title;
    title = getJSXItemWithPrefixesWithStyle(
      prefixesItems,
      item,
      itemStyle,
      hidePrefixes
    );
    return { title: title, value: value };
  };

  dataSchemas?.[0]?.columnNormalizedNames.forEach(
    (columnNormalizedName, index) => {
      //loop for non question block

      //fields to include in treeData
      if (!columnNormalizedNamesToExclude.includes(columnNormalizedName)) {
        let treeDataItem = getTreeDataItem(
          columnNormalizedName,
          index,
          dataSchemas[0],
          selectedDataZource.configuration.formFields,
          formFields,
          view,
          "nonQB",
          isFilterByCategorical
        );

        if (treeDataItem !== null) treeData.push(treeDataItem);
      }
    }
  );

  //question block if exists
  const fieldQB = selectedDataZource?.configuration?.formFields?.find(
    (field) => !!field?.formFields
  );
  if (fieldQB) {
    let treeDataItemQB = {
      title: getJSXItemWithPrefixesWithStyle(
        prefixesItems,
        fieldQB.name,
        itemStyle,
        hidePrefixes
      ),
      value: fieldQB.nameNormalized,
      selectable: false,
      children: dataSchemas[1].columnNormalizedNames
        .map((columnNormalizedNameQB, indexQB) => {
          //loop for non question block

          //fields to include in treeDataQB
          if (
            !columnNormalizedNamesToExclude.includes(columnNormalizedNameQB)
          ) {
            const formConfigurationFormFieldsQB =
              selectedDataZource.configuration.formFields.find(
                (field) => !!field?.formFields
              ).formFields;
            return getTreeDataItem(
              columnNormalizedNameQB,
              indexQB,
              dataSchemas[1],
              formConfigurationFormFieldsQB,
              formFields,
              view,
              "QB",
              isFilterByCategorical
            );
          } else {
            return null;
          }
        })
        .filter((item) => item !== null),
    };

    // filtering not null items
    if (treeDataItemQB.children.length !== 0) treeData.push(treeDataItemQB);
  }

  return treeData;
};

/**
    Parse selectedDataZource formFields to extract those that have categories.
    @param selectedDataZource
    @return An array of objects with keys 'nameNormalized' and 'categories'. 
*/
export const getCategoriesByNameNormalizedInForm = (selectedDataZource) => {
  if (!selectedDataZource) return [];

  let categoriesByVariables_ = [];

  //categoriesByVariables_
  selectedDataZource.configuration.formFields.forEach((formField) => {
    if (formField?.optionValues?.values?.length > 0) {
      //main block categories
      categoriesByVariables_.push({
        nameNormalized: formField.nameNormalized,
        categories: formField.optionValues.values,
      });
    }

    if (formField?.formFields) {
      //question block
      formField.formFields.forEach((formFieldQB) => {
        if (formFieldQB?.optionValues?.values?.length > 0) {
          //question block categories
          categoriesByVariables_.push({
            nameNormalized: formFieldQB.nameNormalized,
            categories: formFieldQB.optionValues.values,
          });
        }
      });
    }
  });

  if (selectedDataZource?.saveUserName) {
    categoriesByVariables_.push({
      nameNormalized: "user_name",
      categories: selectedDataZource.usersNames,
    });
  }

  return categoriesByVariables_;
};

/**
    Parse selectedDataZource formFields to extract those that have categories.
    @param selectedDataZource
    @return An array of objects with keys 'nameNormalized' and 'categories'. 
*/
export const getCategoriesByNameNormalizedInForm_but_modified_with_prefixes = (
  selectedDataZource
) => {
  if (!selectedDataZource) return [];

  let categoriesByVariables_ = [];

  //categoriesByVariables_
  selectedDataZource.configuration.formFields.forEach((formField) => {
    if (formField?.optionValues?.values?.length > 0) {
      //main block categories
      categoriesByVariables_.push({
        nameNormalized: `m${selectedDataZource.id}.${formField.nameNormalized}`,
        categories: formField.optionValues.values,
      });
    }

    if (formField?.formFields) {
      //question block
      formField.formFields.forEach((formFieldQB) => {
        if (formFieldQB?.optionValues?.values?.length > 0) {
          //question block categories
          categoriesByVariables_.push({
            nameNormalized: `qb${selectedDataZource.id}.${formFieldQB.nameNormalized}`,
            categories: formFieldQB.optionValues.values,
          });
        }
      });
    }
  });

  if (selectedDataZource?.saveUserName) {
    categoriesByVariables_.push({
      nameNormalized: `m${selectedDataZource.id}.user_name`,
      categories: selectedDataZource.usersNames,
    });
  }

  return categoriesByVariables_;
};

/**
    Sets the state 'selectedDataZources'
    @param dataSeries Array with the dataSeries that contains the dataZource ids..    
    @param setValue Hook to set the state of seleectedDataZources.
*/
export const getSelectedDataZourcesFromAPI_using_getSurveyForm = (
  dataSeries,
  setSelectedDataZources,
  getSurveyForm,
  dispatch,
  intl,
  dataZourceType
) => {
  if (!dataSeries || !Array.isArray(dataSeries)) {
    const errorMsg = intl.formatMessage({
      id: "errorMsg.getSelectedDataZourcesFromAPI_using_getSurveyForm.invalidArgument.dataSeries",
    });
    throw new Error(errorMsg);
  }

  const selectedDataZourcesId = dataSeries
    ?.map((dataSeriesItem) => dataSeriesItem[dataZourceType])
    .filter((item) => !!item || item?.length > 0)
    .flat(2);

  if (selectedDataZourcesId?.length >= 1) {
    // const asyncFunctions = selectedDataZourcesId.map((selectedDataZourceId) => {
    //   const promise = new Promise((resolve, reject) => {
    //     getSurveyForm(null, selectedDataZourceId)(dispatch).then((data) => {
    //       if (data) {
    //         resolve(data);
    //       } else {
    //         reject(`No data source returned for id: ${selectedDataZourceId}`);
    //       }
    //     });
    //   });
    //   return promise;
    // });

    const asyncFunctions = selectedDataZourcesId.map((selectedDataZourceId) => {
      const asyncFunc = getSurveyForm(null, selectedDataZourceId)(dispatch);
      return asyncFunc;
    });

    Promise.allSettled(asyncFunctions).then((results) => {
      if (results.some((result) => result.status !== "fulfilled")) {
        const notLoadedDataZources = results
          .map((result, index) => {
            if (result.status !== "fullfiled") {
              return index;
            } else {
              return null;
            }
          })
          .filter((item) => item !== null);

        const errorMsg = intl.formatMessage(
          {
            id: "errorMsg.getSelectedDataZourcesFromAPI_using_getSurveyForm.notLoaded",
          },
          { notLoadedDataZources: `[${notLoadedDataZources.join(", ")}]` }
        );

        console.log(errorMsg);
        throw new Error(errorMsg);
      } else {
        setSelectedDataZources(() => {
          const values = results.map((result) => result.value);

          if (dataZourceType === "dataZource") {
            return values;
          }

          if (dataZourceType === "additionalDataZources") {
            const selectedAdditionalDataZourcesId = dataSeries?.map(
              (dataSeriesItem) => dataSeriesItem[dataZourceType]
            );

            let additionalValues = [];
            selectedAdditionalDataZourcesId.forEach((idArray, index) => {
              let valueArray = [];
              if (idArray && idArray?.length > 0) {
                idArray.forEach((id) => {
                  valueArray.push(values.find((item) => item.id === id));
                });

                additionalValues[index] = valueArray;
              }
            });

            return additionalValues;
          }
        });
      }
    });
  } else {
    setSelectedDataZources([]);
  }
};

export const getColumnInfo = (
  selectedDataZource,
  columnNormalizedName,
  unexpectedErrorMsg,
  intl
) => {
  if (!selectedDataZource || !columnNormalizedName) return null;

  const columnNormalizedNameSplitted = columnNormalizedName.split(".");
  const actualColumnNormalizedName =
    columnNormalizedNameSplitted.length === 2
      ? columnNormalizedNameSplitted[1]
      : columnNormalizedNameSplitted[0];

  let columnInfo = {};
  let columnNormalizedNames, columnFormNames, columnFormFieldTypes, dataTypes;

  const dataSchemas = selectedDataZource?.dataSchemas;
  let schemaIndex = 0;
  columnNormalizedNames = dataSchemas[schemaIndex].columnNormalizedNames;
  let index = columnNormalizedNames.indexOf(actualColumnNormalizedName);

  if (index === -1 && dataSchemas.length === 2) {
    schemaIndex = 1;
    columnNormalizedNames = dataSchemas[schemaIndex].columnNormalizedNames;
    index = columnNormalizedNames.indexOf(
      actualColumnNormalizedName === "date_createdQB"
        ? "date_created"
        : actualColumnNormalizedName
    );
  }

  if (index !== -1) {
    //columnNormalizedName is in dataSchemas[0]
    columnFormNames = dataSchemas[schemaIndex].columnFormNames;
    columnFormFieldTypes = dataSchemas[schemaIndex].columnFormFieldTypes;
    dataTypes = dataSchemas[schemaIndex].dataTypes;

    columnInfo.columnFormName = columnFormNames[index];
    columnInfo.columnFormFieldType = columnFormFieldTypes[index];
    columnInfo.dataType = dataTypes[index];
    columnInfo.tableType = "m";

    return columnInfo;
  }

  if (index === -1) {
    //columnNormalizedName is not in dataSchemas[0] and there is not dataSchemas[1]
    const currentErrorMsg = intl.formatMessage(
      {
        id: "HelpersForFormListDataSeries.getColumnInfo.invalid.columnNormalizedName",
      },
      { columnNormalizedName: columnNormalizedName }
    );
    const errorMsg = `${unexpectedErrorMsg} ${currentErrorMsg}`;
    console.log(errorMsg);
    throw new Error(errorMsg);
  }
};

//CODE IN COMMENTS TO BE DELETED
//**********************************************

//import ReactDOM from "react-dom";

//import { throwCustomErrorInvalidValueOfVariable } from "../utils/multiSourceUtils";

// export const getItemTextContentFromJSXElement = (jsxElement) => {
//   // Create a detached DOM node
//   let detachedNode = document.createElement("div");

//   // Render the JSX to the detached node
//   ReactDOM.render(jsxElement, detachedNode);

//   // Query the DOM for the span element
//   let spanElement = detachedNode.querySelectorAll(`.${JSX_TEXT_ITEM_CLASS}`);

//   // Validating spanElement
//   if (spanElement.length === 0) {
//     throwCustomErrorInvalidValueOfVariable(
//       'spanElement',
//       {'value': 'spanElement.length is 0'},
//       'getItemTextContentFromJSXElement',
//       [
//         "it is an empty nodeList, so it means that there is not matched html element with class",
//         `'${JSX_TEXT_ITEM_CLASS}' in current detached node`
//       ].join(' '),
//       'HelpersForFormListDataSeries.js'
//     );
//   }

//   // Validating spanElement.textContent
//   if (!spanElement?.textContent) {
//     throwCustomErrorInvalidValueOfVariable(
//       'spanElement.textContent',
//       {'value': spanElement.textContent},
//       'getItemTextContentFromJSXElement',
//       [
//         "it is null, undefined or an empty string"
//       ].join(' '),
//       'HelpersForFormListDataSeries.js'
//     );
//   }
//   // Extract the value of the span element
//   const spanValue = spanElement.textContent;

//   // Cleaning nodes
//   detachedNode = null;
//   spanElement = null;

//   return spanValue;
// };
