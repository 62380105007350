import React, { useEffect, useMemo, useState } from "react";
import { Button, message } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { useIntl } from "react-intl";

const ShowMessages = ({ isDataPlotted, messagesList }) => {
  const intl = useIntl();
  const [messageOpen, setMessageOpen] = useState(false);

  // Function to filter unique messages
  const filterUniqueObjects = (messages) => {
    return messages.filter((message, index, self) => {
      return (
        index ===
        self.findIndex(
          (t) => t.title === message.title && t.content === message.content
        )
      );
    });
  };

  // Function to check if each message is valid
  const isMessageValid = (message) => {
    return message && message.title && message.content;
  };

  const uniqueMessagesList = useMemo(() => {
    if (!messagesList || messagesList.length === 0) return [];

    //check validity of messagesList items
    const validMessagesList = messagesList.filter((message) =>
      isMessageValid(message)
    );

    if (validMessagesList.length === 0) return [];

    //filter unique messages in list
    const uniqueMessages = filterUniqueObjects(validMessagesList);

    return uniqueMessages;
  }, [messagesList]);

  const buildMessagesListJSX = () => {
    return (
      <div style={{ textAlign: "left" }} id={"messagesToShow"}>
        {uniqueMessagesList
          .filter((messageObj) => !!messageObj.content)
          .map((messageObj, i) => (
            <p key={i}>
              <span style={{ fontWeight: "bold", marginBottom: "0" }}>
                {messageObj.title}
              </span>
              <span style={{ marginLeft: "0.5em" }}>{messageObj.content}</span>
            </p>
          ))}
      </div>
    );
  };

  const showMessages = () => {
    message.warning({
      content: buildMessagesListJSX(),
      style: { width: "85%", marginLeft: "auto", marginRight: "auto" },
      onClose: handleClose,
    });
  };

  useEffect(
    function useEffect_showMessages() {
      if (!uniqueMessagesList || uniqueMessagesList.length === 0 || !isDataPlotted || messageOpen) return;

      setMessageOpen(true);
      showMessages();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [uniqueMessagesList, isDataPlotted]
  );

  // Function to handle the warning message close event
  const handleClose = () => {
    setMessageOpen(false);
  };

  // Function to handle the button click event
  const handleClick = () => {
    if (!messageOpen) {
      setMessageOpen(true);
      showMessages();
    }
  };

  // Render the button and the list of messages
  //jxs
  return (
    <Button
      type={"text"}
      style={{
        float: "right",
        width: "10px",        
        zIndex: 200,
      }}
      onClick={handleClick}
      title={intl.formatMessage({
        id: "ShowMessages.button.title",
      })}
      hidden={!messagesList|| !messagesList?.length > 0 || !isDataPlotted}
    >
      <ExclamationCircleFilled
        style={{
          color: "orange",
          position: "absolute",
          right: "10%",
          left: "10%",
          top: "27%",
        }}
      />
    </Button>
  );
};

export default ShowMessages;
