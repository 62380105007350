import ReactDOM from "react-dom";
import { Image } from "antd";
import "./helpersForPopup.css";

export const getPopupDOMContent2 = (popupDataJSON, data) => {
  const popupData = JSON.parse(popupDataJSON);

  const popupContainerElement = document.createElement("div");
  popupContainerElement.classList.add("custom-popup-divContainerElement");

  //displaying title
  const titleElement = get_titleElement(popupData.title);
  popupContainerElement.appendChild(titleElement);

  //line
  const lineElement = document.createElement("hr");
  lineElement.classList.add("custom-popup-lineElement");
  popupContainerElement.appendChild(lineElement);

  //the region label

  const label = data["level"];
  const variableValue = data["name"];
  if (label && variableValue) {
    const variable = { alias: label, dataType: "TEXT" };
    const regionElement = get_pElement(variable, variableValue);
    popupContainerElement.appendChild(regionElement);
  }

  popupData.variables.forEach((item) => {
    const variableValue_ = data[item.alias];
    let variableElement;
    if (data?.isPhoto?.content?.[item?.alias]) {
      variableElement = get_imgElement(item, variableValue_);
    } else {
      variableElement = get_pElement(item, variableValue_);
    }

    popupContainerElement.appendChild(variableElement);
  });

  return popupContainerElement;
};

const get_titleElement = (title) => {
  const pElement = document.createElement("p");
  pElement.classList.add("custom-popup-titleElement");

  const spanElementAlias = document.createElement("span");
  spanElementAlias.classList.add("custom-popup-title");
  spanElementAlias.innerText = title;
  pElement.appendChild(spanElementAlias);

  return pElement;
};

const get_pElement = (variable, variableValue) => {
  const pElement = document.createElement("p");
  pElement.classList.add("custom-popup-pElement");

  const spanElementAlias = document.createElement("span");
  spanElementAlias.classList.add("custom-popup-alias");
  spanElementAlias.innerText = variable.alias + ": ";
  pElement.appendChild(spanElementAlias);

  const spanElementValue = document.createElement("span");
  spanElementValue.classList.add("custom-popup-value");
  spanElementValue.innerText = variableValue;
  pElement.appendChild(spanElementValue);

  return pElement;
};

const get_imgElement = (variable, variableValue) => {
  if (
    variableValue !== undefined &&
    variableValue !== null &&
    variableValue !== ""
  ) {
    const pElement = document.createElement("p");
    pElement.classList.add("custom-popup-pElement");

    const spanElementAlias = document.createElement("span");
    spanElementAlias.classList.add("custom-popup-alias");
    spanElementAlias.innerText = variable.alias + ": ";
    pElement.appendChild(spanElementAlias);

    const imgDivContainer = document.createElement("div");
    imgDivContainer.classList.add("custom-popup-imgDivContainer");

    const imgJSX = <Image width={150} src={`data:${variableValue}`} />;
    ReactDOM.render(imgJSX, imgDivContainer);

    const imageElement = document.createElement("div");
    imageElement.classList.add("custom-popup-imageElement");

    imageElement.appendChild(pElement);
    imageElement.appendChild(imgDivContainer);

    return imageElement;
  } else {
    return get_pElement(variable, variableValue);
  }
};

export const getPopupDOMContentRegion = (level, name) => {
  const popupContainerElement = document.createElement("div");
  popupContainerElement.classList.add("custom-popup-divContainerElement");

  //region
  const label = level;
  const variableValue = name;
  const variable = { alias: label, dataType: "TEXT" };
  const regionElement = get_pElement(variable, variableValue);
  popupContainerElement.appendChild(regionElement);

  return popupContainerElement;
};

//popup - function showPopup
export const showPopup = (
  e,
  feature,
  analysisId,
  getPopupData,
  getPopupDOMContent2,
  mapboxgl,
  mapRef,
  message,
  setValuePopupQuery
) => {
  let isPopup = feature?.properties.popupData !== null;
  if (isPopup) {
    const coordinates = feature.geometry.coordinates.slice();
    const dataId = feature.properties.dataId;
    const seriesIndex = feature.properties.seriesIndex;
    //getting data from API
    getPopupData(analysisId, dataId, seriesIndex)().then((data) => {
      if (data?.errors?.length === 0) {
        const popupData = feature.properties.popupData;

        // Ensure that if the map is zoomed out such that multiple
        // copies of the feature are visible, the popup appears
        // over the copy being pointed to.
        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }
        const actualData = data.data[0][0];

        new mapboxgl.Popup({
          maxWidth: "400px",
          className: "popup-cases-1234",
        })
          .setLngLat(coordinates)
          .setDOMContent(getPopupDOMContent2(popupData, actualData))
          .addTo(mapRef.current);
      } else {
        message.error(data.query[0]);
      }

      let textToInsert = [
        "--********************************",
        "--Popup query",
        "--********************************",
        data.query[0],
      ].join("\n");

      const editorDelta = {
        //inserting a Delta as value to show text with format
        ops: [{ insert: textToInsert }],
      };
      setValuePopupQuery(editorDelta);
    });
  }
};
