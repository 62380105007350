import { Card, Modal, Row } from "antd";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { limitByUserRole } from "../global-limits-by-user-role";

const LimitByUserRoleModal = ({
  currentUser,
  componentType,
  count,
  history,
  triggerObj,
  setTriggerObj
}) => {
  const intl = useIntl();

  const [limitByUserRoleInfo, setLimitByUserRoleInfo] = useState(
    <Card loading={true}> </Card>
  );

  const {isLimitModalVisible, url} = triggerObj;

  const showModal = () => {
    setLimitByUserRoleInfo(
      <Card>
        <Row>
          {intl.formatMessage(
            { id: "limit-by-user-role.greetings" },
            {
              userName: currentUser.firstName + " " + currentUser.lastName,
            }
          )}
        </Row>
        <Row>{intl.formatMessage({ id: "limit-by-user-role.p1" })}</Row>
        <Row>{intl.formatMessage({ id: "limit-by-user-role.p2" })}</Row>
        <Row>{intl.formatMessage({ id: "limit-by-user-role.p3" })}</Row>
        <Row>
          <ul>
            <li>
              {intl.formatMessage({ id: "limit-by-user-role.list.item1" })}
            </li>
            <li>
              {intl.formatMessage({ id: "limit-by-user-role.list.item2" })}
            </li>
            <li>
              {intl.formatMessage({ id: "limit-by-user-role.list.item3" })}
            </li>
          </ul>
        </Row>
        <Row>
          <span>
            {intl.formatMessage({ id: "limit-by-user-role.p4.before" })}
            <a
              href={"https://www.vectoranalytica.com/products/"}
              target="_blank"
              rel="noreferrer"
              style={{ marginLeft: "5px", marginRight: "5px" }}
            >
              {intl.formatMessage({ id: "limit-by-user-role.p4.product-page" })}
            </a>
            {intl.formatMessage({ id: "limit-by-user-role.p4.after" })}
          </span>
        </Row>
        <Row>
          {intl.formatMessage({ id: "limit-by-user-role.p5" })}
          <a
            href={"https://www.vectoranalytica.com/contact-us/"}
            target="_blank"
            rel="noreferrer"
            style={{marginLeft: '5px'}}
          >
            {intl.formatMessage({
              id: "limit-by-user-role.contact-us",
            })}
          </a>
        </Row>
      </Card>
    );
  };

  const handleOk = () => {
    setTriggerObj({isLimitModalVisible: false, url: null});
  };

  const handleCancel = () => {
    setTriggerObj({isLimitModalVisible: false, url: null});
  };

  useEffect(
    function useEffect_showModal() {
      if (!isLimitModalVisible) return;
      if (
        currentUser.permissions.includes("ROLE_FREE_USER") &&
        limitByUserRole["ROLE_FREE_USER"][componentType] === count
      ) {
        showModal("ROLE_FREE_USER");
      } else if (
        currentUser.permissions.includes("ROLE_BASIC_USER") &&
        limitByUserRole["ROLE_BASIC_USER"][componentType] === count
      ) {
        showModal("ROLE_BASIC_USER");
      } else if (
        currentUser.permissions.includes("ROLE_PREMIUM_USER") &&
        limitByUserRole["ROLE_PREMIUM_USER"][componentType] === count
      ) {
        showModal("ROLE_PREMIUM_USER");
      } else {
        history.push(url);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLimitModalVisible]
  );

  //jsx
  return (
    <Modal
      title={intl.formatMessage({
        id: "label.limits-by-user-role",
      })}
      open={isLimitModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      centered
      width={720}
    >
      {limitByUserRoleInfo}
    </Modal>
  );
};

export default LimitByUserRoleModal;
