import {Form, Input, Select, message, Breadcrumb, Layout, Card, Button} from 'antd';
import {FormattedMessage, useIntl} from "react-intl";
import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {
    setLoading,
    clearLoading,
    addRole,
    getRole,
    editRole,
    clearRoleError
} from "../../redux/role/roleActions";
import {HomeOutlined} from "@ant-design/icons";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {selectError, selectLoading, selectCurrentRole} from "../../redux/role/roleSelector";
import {selectRoles} from "../../redux/role/roleSelector";
import {listPermissions} from "../../redux/permission/permissionActions";
import {selectPermissions} from "../../redux/permission/permissionSelector";

const {Content, Footer} = Layout;


const RoleForm = (props) => {

    const intl = useIntl();
    const dispatch = useDispatch();

    const [roleCreated, setRoleCreated] = useState(false);
    const [edit, setEdit] = useState(props.location.pathname !== '/admin/roles/new');

    const [form] = Form.useForm();

    const onFinish = (values) => {
        if (edit) {
            props.editRole(props.history, props.match.params.id, values, setRoleCreated);
        } else {
            props.addRole(props.history, values, setRoleCreated);
        }

    };

    const onFinishFailed = (errorInfo) => {
        errorInfo.errorFields.map(error => (message.error(error.errors[0])));
    };

    const [permissionList, setPermissionList] = useState([{
        label: intl.formatMessage({id: 'label.select'}),
        value: null
    }])

    useEffect(() => {
        if (permissionList.length === 1) {
            dispatch(setLoading());
            if (props.permissions === null || props.permissions.length === 0) {
                dispatch(listPermissions(props.history));
            } else {
                const loadedPermissions = [...permissionList];
                props.permissions?.permissions?.map(permission => loadedPermissions.push({
                    label: permission.name,
                    value: permission.id
                }))
                setPermissionList(loadedPermissions.sort((a,b) => a.label === b.label ? 0 : a.label < b.label ? -1 : 1));

                dispatch(clearLoading());
            }
        }
        if (props.error) {
            const error = () => {
                if (typeof props.error == "string") {
                    message.error(props.error).then(props.clearRoleError());
                } else if (props.error.hasOwnProperty('message')) {
                    message.error(props.error.message).then(props.clearFormDataError());
                } else {
                    props.error.errors.map(errorMessage =>
                        (message.error(errorMessage.message).then(props.clearRoleError())));
                }

            };
            error();
        }

        if (edit && !roleCreated && props.error === null) {
            props.getRole(props.history, props.match.params.id).then((data) => {
                if (data) {
                    form.setFieldsValue({
                        'authority': data.authority,
                        'permissions': data.permissions.map((permission) => (permission.id))
                    });
                } else {
                    setEdit(false);
                }
            })
        }

        if (roleCreated) {
            if (edit) {
                message.info(intl.formatMessage({id: 'label.role-updated'}));
            } else {
                form.resetFields();
                message.info(intl.formatMessage({id: 'label.role-created'}));
            }
            setRoleCreated(false);
        }
        // eslint-disable-next-line
    }, [props.error, roleCreated, edit, props.permissions])

    return (
        <Layout className="site-layout">
            <Content style={{margin: '0 16px'}}>
                <Breadcrumb style={{margin: '10px 0'}}>
                    <Breadcrumb.Item>
                        <HomeOutlined/>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <span><FormattedMessage id="label.role" defaultMessage="Role"/></span>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <span>{edit ? (<FormattedMessage
                            id="label.edit" defaultMessage="Edit"/>) : (
                            <FormattedMessage id="label.new" defaultMessage="New"/>)}</span>
                    </Breadcrumb.Item>
                </Breadcrumb>
                <div className="site-layout-background" style={{padding: 24, minHeight: 360}}>
                    <Card type="inner"
                          title={edit ? <FormattedMessage id="label.edit-role" defaultMessage="Edit Role"/> :
                              <FormattedMessage id="label.create-role" defaultMessage="Add Role"/>}
                          extra={<Link to='/admin/roles'><FormattedMessage id="label.return"
                                                                           defaultMessage="Return"/></Link>}>
                        <Form layout="horizontal" name="role_form" size="large"
                              labelCol={{span: 3,}} wrapperCol={{span: 14,}}
                              initialValues={{active: true}} form={form}
                              onFinish={onFinish} onFinishFailed={onFinishFailed}>
                            <Form.Item
                                name="authority"
                                label={intl.formatMessage({id: 'label.name'})}
                            >
                                <Input/>
                            </Form.Item>
                            <Form.Item name="permissions" label={intl.formatMessage({id: 'menu.permissions'})}
                                       rules={[{
                                           required: true,
                                           message: intl.formatMessage({id: 'msg.input-required'})
                                       }]}>
                                <Select options={permissionList} mode='multiple'>

                                </Select>
                            </Form.Item>

                            <Form.Item>
                                <Button type="primary" htmlType="submit" loading={props.loading}>
                                    {edit ? (<FormattedMessage id="label.update" defaultMessage="Update"/>) : (
                                        <FormattedMessage id="label.submit" defaultMessage="Submit"/>)}

                                </Button>
                            </Form.Item>
                        </Form>
                    </Card>
                </div>
            </Content>
            <Footer style={{textAlign: 'center'}}>©{new Date().getFullYear()}</Footer>
        </Layout>
    );
};

const mapStateToProps = state => ({
    loading: selectLoading(state),
    error: selectError(state),
    role: selectCurrentRole(state),
    roles: selectRoles(state),
    permissions: selectPermissions(state)
});

export default connect(mapStateToProps, {addRole, getRole, editRole, clearRoleError})(RoleForm);