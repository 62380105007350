import {
  Form,
  Input,
  message,
  Breadcrumb,
  Layout,
  Card,
  Button,
  Select,
  Col,
  Row,
  Skeleton,
  TreeSelect,
  Checkbox,
} from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import React, {
  useEffect,
  useRef,
  useState,
  Suspense,
  StrictMode,
  useMemo,
} from "react";
import {
  addDataAnalysis,
  getDataAnalysis,
  editDataAnalysis,
  clearDataAnalysisError,
  updateReactFormData,
  clearUpdateReactFormDataDataAnalysisError,
  getCurrentDataAnalysisGeoAreaByProjectsId,
} from "../../redux/dataAnalysis/dataAnalysisActions";
import { EyeTwoTone, HomeOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import moment from "moment";
import {
  listSurveyForms,
  getSurveyForm,
} from "../../redux/survey/form/surveyFormActions";
import { listFormFields } from "../../redux/survey/formField/formFieldActions";
import { listProjects } from "../../redux/project/projectActions";
import { useDispatch } from "react-redux";
import { getDataForAnalysis } from "../../utils/utilData";
import ReactQuill from "react-quill";
import {
  getConfigViewForUser,
  updateConfigView,
  resetConfigView,
} from "../../utils/dataAnalysis/utils/helpersForConfigView";
import "./DataAnalysisForm.css";
import { PlotChoroplethMapMapBoxGlJs2 } from "../../utils/dataAnalysis/Plots/PlotChoroplethMapMapBoxGlJs2";

import {
  getDeepestPath,
  getFieldPath,
  getFieldPathString,
  getIsFieldChanged,
  isXVariableOfDateType,
} from "../../utils/dataAnalysis/utils/utilsForm";

import _ from "lodash";

import {
  getJSXItemWithPrefixesWithStyle,
  getSelectedDataZourcesFromAPI_using_getSurveyForm,
} from "../../utils/dataAnalysis/DataSeries/HelpersForFormListDataSeries";
import {
  getDifferentItems,
  getUniqueKeys,
  JSONParseStringify,
} from "../../utils/dataAnalysis/utils/utils";
import { throwCustomErrorInvalidValueOfVariable } from "../../utils/dataAnalysis/utils/multiSourceUtils";
import { extractProjectFormsAndName } from "../../utils/dataAnalysis/utils/helpersForDefaultDataAnalyses";
import LoadingSpinWithMessage from "../../utils/dataAnalysis/LoadingSpinWithMessage";

const FormItemsAccordingToAnalysisType = React.lazy(() =>
  import("../../utils/dataAnalysis/FormItemsAccordingToAnalysisType")
);
const PlotTimeSeries = React.lazy(() =>
  import("../../utils/dataAnalysis/Plots/PlotTimeSeries")
);
const PlotBarPlot = React.lazy(() =>
  import("../../utils/dataAnalysis/Plots/PlotBarPlot")
);
const PlotHeatMapMapBoxGlJs = React.lazy(() =>
  import("../../utils/dataAnalysis/Plots/PlotHeatMapMapBoxGlJs")
);
const PlotBubbleMapMapBoxGlJs = React.lazy(() =>
  import("../../utils/dataAnalysis/Plots/PlotBubbleMapMapBoxGlJs")
);
const PlotPointMapMapBoxGlJsSimple2 = React.lazy(() =>
  import("../../utils/dataAnalysis/Plots/PlotPointMapMapBoxGlJsSimple2")
);
const PlotPointMapMapBoxGlJsCategorical2 = React.lazy(() =>
  import("../../utils/dataAnalysis/Plots/PlotPointMapMapBoxGlJsCategorical2")
);

const { Content, Footer } = Layout;

//TODO: Improve Suspense behavior. Unexpected parcial rendering are showed.

export const DataAnalysisFormContext = React.createContext({});

const DataAnalysisForm = (props) => {
  const dispatch = useDispatch();

  const intl = useIntl();
  const [form] = Form.useForm();

  //-------------------------------------------------------
  //variables and functions from props and context - start
  //-------------------------------------------------------
  const analysisId = props.match.params?.id;

  //useSelector
  const loading = useSelector((state) => state.dataAnalysis.loading);
  const error = useSelector((state) => state.dataAnalysis.error);
  const dataAnalysis = useSelector((state) => state.dataAnalysis.current);
  const referenceGeoAreas = useSelector(
    (state) => state.dataAnalysis.currentGeoAreas
  );
  const changedField = useSelector(
    (state) => state.dataAnalysis.reactFormData?.changedField
  );
  const projects = useSelector((state) => state.project.projects.projects);
  const dataZources = useSelector(
    (state) => state.surveyForm.surveyForms.forms
  );
  const formFields = useSelector(
    (state) => state.formField.formFields.formFields
  );

  //-----

  const configEdit = useMemo(
    function setConfigEdit() {
      return dataAnalysis?.configEdit ? dataAnalysis.configEdit : [];
    },
    [dataAnalysis?.configEdit]
  );

  const edit = props.location.pathname !== "/configuration/dataAnalysis/new";
  const isBarPlots = props.location.pathname.includes("bar-plots");
  const isBubbleMaps = props.location.pathname.includes("bubble-maps");
  const isChoroplethMaps = props.location.pathname.includes("choropleth-maps");
  const isConfig = props.location.pathname.includes("configuration");
  const isHeatMaps = props.location.pathname.includes("heat-maps");
  const isNew = props.location.pathname.includes("new");
  const isPointMaps = props.location.pathname.includes("point-maps");
  const isQuery = props.location.pathname.includes("/query/view");
  const isTimeSeries = props.location.pathname.includes("time-series");
  const view = props.location.pathname.includes("view");
  const formMode = useMemo(() => {
    const pathName = props.location.pathname;

    let mode;
    if (pathName.includes("new")) {
      mode = "new";
    } else if (pathName.includes("view")) {
      mode = "view";
    } else {
      //TODO: include 'edit' in props.locations.pathname for a more precise identification of analysisMod
      mode = "edit";
    }

    return mode;
  }, [props.location.pathname]);

  const dateFormat = view ? "YYYY-MM-DD" : null;
  const timeFormat = view ? "HH:mm" : null;

  //form field path for x variable in TimeSeries and BarPlot analysis
  const xVariables0Path = [
    "configEdit",
    "dataSeries",
    0,
    "xaxes",
    0,
    "variables",
    0,
    "variables",
  ];

  const unexpectedErrorMsg = intl.formatMessage({
    id: "errorMsg.unexpectedError",
  });

  const LoadingProjectOptions = () => {
    return (
      <LoadingSpinWithMessage
        customMessageId={"loading.DataAnalysisForm.projects.options.message"}
      />
    );
  };
  //only those projects that currently have forms are included in projectsSelectOptionsMemo
  const projectsSelectOptionsMemo = useMemo(
    () => {
      if (projects === undefined) return [];

      const hasFormsProjects = projects?.filter((project) => project.hasForms);
      if (hasFormsProjects?.length > 0) {
        return hasFormsProjects.map((project) => {
          const prefixesItems = ["p" + project.id];
          const item = project.name;
          const itemStyle = undefined;
          const hidePrefixes = view;
          return {
            label: getJSXItemWithPrefixesWithStyle(
              prefixesItems,
              item,
              itemStyle,
              hidePrefixes
            ),
            value: project.id,
          };
        });
      } else {
        const msgWarning = intl.formatMessage({
          id: "warningMsg.DataAnalysisForm.noProjectHasForms.cannotCreateAnalysis",
        });
        message.warning(msgWarning);
        console.log(msgWarning);
        return [];
      }
    },
    //eslint-disable-next-line
    [projects]
  );

  //-------------------------------------------------------
  //variables and functions from props and context - end
  //-------------------------------------------------------

  //-------------------------------------
  //component specific variables - start
  //-------------------------------------
  const configEditFieldName = "configEdit"; //name of the field that collects data in JSONb
  // for entity DataAnalysis in the API

  //root path in form for configEdit field
  const rootPathConfigEdit = [configEditFieldName];

  //-------------------------------------
  //component specific variables - end
  //-------------------------------------

  //--------------------------------------------
  //other variables and local functions - start
  //--------------------------------------------

  const mapAnalyses = [
    "SimplePointMap",
    "CategoricalPointMap",
    "HeatMap",
    "ChoroplethMap",
    "BubbleMap",
  ];
  const otherAnalyses = ["TimeSeries", "BarPlot"];

  const antDividerBorderTopColor = "rgba(0,0,0,0.85)";

  const plotHeight = 500;

  //these values must be the same as the ones in DataAnalysis.groovy:
  //type inList: ['TimeSeries', 'BarPlot', 'SimplePointMap', 'CategoricalPointMap' , 'HeatMap', 'ChoroplethMap', 'BubbleMap']
  const treeDataAnalysisTypeField = [
    {
      label: intl.formatMessage({ id: "label.dataAnalysis.type.TimeSeries" }),
      value: "TimeSeries",
    },
    {
      label: intl.formatMessage({ id: "label.dataAnalysis.type.BarPlot" }),
      value: "BarPlot",
    },
    {
      label: intl.formatMessage({ id: "label.dataAnalysis.type.PointMap" }),
      value: "PointMap",
      selectable: false,
      children: [
        {
          label: intl.formatMessage({
            id: "label.dataAnalysis.type.CategoricalPointMap",
          }),
          value: "CategoricalPointMap",
        },
        {
          label: intl.formatMessage({
            id: "label.dataAnalysis.type.SimplePointMap",
          }),
          value: "SimplePointMap",
        },
      ].sort((a, b) =>
        a["label"] === b["label"] ? 0 : a["label"] < b["label"] ? -1 : 1
      ),
    },
    {
      label: intl.formatMessage({ id: "label.dataAnalysis.type.HeatMap" }),
      value: "HeatMap",
    },
    {
      label: intl.formatMessage({
        id: "label.dataAnalysis.type.ChoroplethMap",
      }),
      value: "ChoroplethMap",
    },
    {
      label: intl.formatMessage({ id: "label.dataAnalysis.type.BubbleMap" }),
      value: "BubbleMap",
    },
  ].sort((a, b) =>
    a["label"] === b["label"] ? 0 : a["label"] < b["label"] ? -1 : 1
  );

  const statusOptions = [
    { label: intl.formatMessage({ id: "label.draft" }), value: "0" },
    { label: intl.formatMessage({ id: "label.published" }), value: "1" },
    { label: intl.formatMessage({ id: "label.disabled" }), value: "2" },
  ];

  //helper - move plot element to the center of viewport, for better visualization, when it is rendered.
  const scrollToAnchor = (anchor) => {
    const parentElement = document.getElementById(anchor);
    const yPadding = 75;
    const y =
      parentElement.getBoundingClientRect().top + window.scrollY - yPadding;
    window.scrollTo({ top: y, behavior: "auto" });
  };

  //helper function to select plot
  const plotAnalysis = () => {
    let element;
    switch (analysisType) {
      case "TimeSeries":
        element = <PlotTimeSeries />;
        break;
      case "BarPlot":
        element = <PlotBarPlot />;
        break;

      case "SimplePointMap":
        element = <PlotPointMapMapBoxGlJsSimple2 />;
        break;

      case "CategoricalPointMap":
        element = <PlotPointMapMapBoxGlJsCategorical2 />;
        break;

      case "HeatMap":
        element = <PlotHeatMapMapBoxGlJs />;
        break;

      case "BubbleMap":
        element = <PlotBubbleMapMapBoxGlJs />;
        break;

      case "ChoroplethMap":
        element = <PlotChoroplethMapMapBoxGlJs2 />;
        break;

      default:
        element = <p>{analysisType + " is not implemented yet!"}</p>;
    }
    return element;
  };

  const optionsConditionsLogicalOperator = [
    { title: intl.formatMessage({ id: "label.AND" }), value: "AND" },
    { title: intl.formatMessage({ id: "label.OR" }), value: "OR" },
  ];

  //--------------------------------------------
  //other variables and local functions - end
  //--------------------------------------------

  //----------------
  //states - start
  //----------------
  const areAllFormFieldsJustFilledByCodeRef = useRef(false);
  const [
    analysisProject,
    setAnalysisProject,
  ] = useState();
  const [analysisRawData, setAnalysisRawData] = useState([]);
  const [analysisType, setAnalysisType] = useState();
  const [dataAnalysisCreated, setDataAnalysisCreated] = useState(false);
  const [loadingAnalysis, setLoadingAnalysis] = useState(false);

  const [isMapAnalysis, setIsMapAnalysis] = useState();
  const [isOtherAnalysis, setIsOtherAnalysis] = useState();

  //selectedD ataZources is an array of items so that each one takes the value
  //returned by getSurveyForm whose id is equal to
  //the value of field dataZource in the corresponding dataSeries.
  const [selectedDataZources, setSelectedDataZources] = useState([]);
  const [selectedAdditionalDataZources, setSelectedAdditionalDataZources] =
    useState([]);

  //this state is set in FormListDataSeriesMaps and then used by MapOptions to
  //generate the categories for 'CategoricalPointMap'.
  const [categoriesByVariables, setCategoriesByVariables] = useState(null);

  /*for BarPlotOptions*/
  const [maxNumberOfBarsSlider, setMaxNumberOfBarsSlider] = useState({
    min: 1,
    max: 30,
    step: 1,
    marks: {
      1: `1`,
      15: `15`,
      30: `30`,
    },
  });

  //used in MapOptions component, but initilized when loadingData here in DataAnalysisForm
  const [radiusDisplay, setRadiusDisplay] = useState(null);
  const [opacityDisplay, setOpacityDisplay] = useState(null);
  const [strokeColorDisplay, setStrokeColorDisplay] = useState(null);
  const [strokeWidthDisplay, setStrokeWidthDisplay] = useState(null);
  const [strokeOpacityDisplay, setStrokeOpacityDisplay] = useState(null);
  const [strokeColor, setStrokeColor] = useState(null);

  //used to indicate that required data is ready to render analysis fragment in form
  const [isReadyAnalysisFormData, setIsReadyAnalysisFormData] = useState(isNew);

  //used to identify when form is fully rendered
  /*TODO: Update the initial state of  wasFormJustRendered to useState(isNew) after setting
   the logic of  wasFormJustRendered*/
  const [wasFormJustRendered, setWasFormJustRendered] = useState(isNew);
  const [dataClonedToFieldsValue, setDataClonedToFieldsValue] = useState();

  //handlers for show and save query
  const [queryAction, setQueryAction] = useState(null);

  //configView
  const [configView, setConfigView] = useState();

  //popup query
  const popupQueryRef = useRef(null);
  const [valuePopupQuery, setValuePopupQuery] = useState("The popup query ...");

  //main query
  const mainQueryRef = useRef(null);
  const [valueMainQuery, setValueMainQuery] = useState("The main query...");

  const [reactFormDataUpdateStatus, setReactFormDataUpdateStatus] = useState({
    status: null,
    error: null,
  });

  const [xVariableCurrentValue, setXVariableCurrentValue] = useState();

  const isXDateType = useMemo(() => {
    if (["BarPlot", "TimeSeries"].includes(analysisType)) {
      return isXVariableOfDateType(
        xVariableCurrentValue,
        selectedDataZources,
        form,
        intl
      );
    } else {
      return false;
    }
  }, [analysisType, xVariableCurrentValue, selectedDataZources, form, intl]);

  const isLocationsBarPlotXVariable = useMemo(() => {
    if (
      !selectedDataZources ||
      selectedDataZources.length === 0 ||
      !xVariableCurrentValue ||
      !analysisType
    )
      return null;

    const selectedDataZource = selectedDataZources[0].id;
    return (
      ["BarPlot"].includes(analysisType) &&
      xVariableCurrentValue === `m${selectedDataZource}.locations`
    );
  }, [analysisType, xVariableCurrentValue, selectedDataZources]);

  //----------------
  //states - end
  //----------------

  //-----------------
  //handlers - start
  //------------------
  const onFinish = (values) => {
    if (!values) {
      return;
    }

    const valuesProjects = Array.isArray(values.projects)
      ? values.projects
      : [values.projects];

    if (
      edit ||
      isTimeSeries ||
      isBarPlots ||
      isHeatMaps ||
      isPointMaps ||
      isChoroplethMaps ||
      isBubbleMaps
    ) {
      if (view) {
        if (values[configEditFieldName].dateRange) {
          //converting dateRange from moment to string with dateFormat
          values[configEditFieldName].dateRange = [
            values[configEditFieldName].dateRange[0]?.format(dateFormat),
            values[configEditFieldName].dateRange[1]?.format(dateFormat),
          ];
        }

        if (values[configEditFieldName].timeRange) {
          //converting timeRange from moment to string with timeFormat
          values[configEditFieldName].timeRange = [
            values[configEditFieldName].timeRange[0]?.format(timeFormat),
            values[configEditFieldName].timeRange[1]?.format(timeFormat),
          ];
        }

        dispatch(
          updateConfigView(
            props.history,
            analysisId,
            { configViewUser: values[configEditFieldName] },
            setDataAnalysisCreated
          )
        );
      } else {
        dispatch(
          editDataAnalysis(
            props.history,
            analysisId,
            {
              status: values.status,
              name: values.name,
              description: values.description,
              projects: valuesProjects,
              type: values.type,
              configEdit: values[configEditFieldName],
            },
            setDataAnalysisCreated
          )
        ).then(() => dispatch(resetConfigView(props.history, analysisId)));
      }
    } else {
      dispatch(
        addDataAnalysis(
          props.history,
          {
            status: values.status,
            name: values.name,
            description: values.description,
            projects: valuesProjects,
            type: values.type,
            configEdit: values[configEditFieldName],
            configView: null,
          },
          setDataAnalysisCreated
        )
      );
    }
  };

  const onFinishFailed = (errorInfo) => {
    errorInfo.errorFields.map((error) => message.error(error.errors[0]));
  };

  const handleShowQuery = () => {
    setQueryAction("show");
    const btn = document.getElementById("form-submit-button");
    btn.click();
  };
  const handleBackFromQuery = () => {
    window.location.href = `/configuration/dataAnalysis/${analysisId}`;
  };

  const handleValuesChange = (changedValues, allValues) => {
    const changedField = {
      name: getDeepestPath(changedValues),
      value: _.get(changedValues, getDeepestPath(changedValues)),
    };

    //update reactFormData state on store
    dispatch(
      updateReactFormData(
        {
          changedField: changedField,
          changedValues: changedValues,
          allValues: allValues,
        },
        setReactFormDataUpdateStatus
      )
    );
  };

  const handleFieldsChange = (changedFields, allFields) => {
    //update reactFormData state on store
    dispatch(
      updateReactFormData(
        {
          changedFields: changedFields,
          allFields: allFields,
        },
        setReactFormDataUpdateStatus
      )
    );
  };

  //-----------------
  //handlers - end
  //------------------

  //-----------------
  //effects - start
  //-----------------

  // Effect to set up the timer
  useEffect(() => {
    if (isNew) {
      setWasFormJustRendered(true);
      dispatch(
        updateReactFormData(
          {
            allFields: form.getFieldsValue(),
          },
          setReactFormDataUpdateStatus
        )
      );
      return;
    } else {
      /*TODO: Build the measure to identify when the form is completly loaded 
        taking into account the processes of lazy loading of nested components.*/
      if (dataClonedToFieldsValue) {
        setWasFormJustRendered(true);

        dispatch(
          updateReactFormData(
            {
              allFields: dataClonedToFieldsValue,
            },
            setReactFormDataUpdateStatus
          )
        );
      }

      return;
    }

    /**
     * TODO: UPDATE THE FOLLOWING CODE AFTER CREATING THE MEASURE TO IDENTIFY WHEN
     * THE FORM IS COMPLETLY LOADED.
     */
    // eslint-disable-next-line no-unreachable
    const intervalId = setInterval(() => {
      const excludedKeysFromClonedData = [
        "dateCreated",
        "lastUpdated",
        "id",
        "projectName",
      ];
      const excludedKeysFromActualFormData = ["levels"];
      const clonedDataKeys = getUniqueKeys(dataClonedToFieldsValue).filter(
        (item) => !excludedKeysFromClonedData.includes(item)
      );
      const actualFormDataKeys = getUniqueKeys(form.getFieldsValue()).filter(
        (item) => !excludedKeysFromActualFormData.includes(item)
      );
      const diffItems = getDifferentItems(clonedDataKeys, actualFormDataKeys);

      const result =
        !isNew &&
        isReadyAnalysisFormData &&
        dataClonedToFieldsValue &&
        (_.isEqual(actualFormDataKeys, clonedDataKeys) || diffItems.length < 5);
      if (result) {
        setWasFormJustRendered(true);
        dispatch(
          updateReactFormData(
            {
              allFields: form.getFieldsValue(),
            },
            setReactFormDataUpdateStatus
          )
        );
        clearInterval(intervalId); // Stop the timer if the condition is met
      }
    }, 1000); // Interval set in ms

    // Clean up the effect by clearing the interval when the component unmounts
    // eslint-disable-next-line no-unreachable
    return () => {
      clearInterval(intervalId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNew, isReadyAnalysisFormData, dataClonedToFieldsValue]);

  useEffect(
    function ue_setChangedField() {
      if (!changedField) return;

      let projectsChanged, analysisProject_;
      let analysisTypeChanged, analysisType_;
      let xVariableChanged;

      //if any field changed by user interaction, then set areAllFormFieldsJustFilledByCodeRef.current to false!
      if (areAllFormFieldsJustFilledByCodeRef.current) {
        areAllFormFieldsJustFilledByCodeRef.current = false;
      }

      projectsChanged = changedField.name[0] === "projects";
      analysisTypeChanged = changedField.name[0] === "type";
      xVariableChanged = getIsFieldChanged(xVariables0Path, changedField.name);

      //updating projects and type
      if (projectsChanged) {
        analysisProject_ = changedField.value;
        setAnalysisProject(analysisProject_);
      } else if (analysisTypeChanged) {
        analysisType_ = changedField.value;
        setAnalysisType(analysisType_);
      } else if (xVariableChanged) {
        setXVariableCurrentValue(changedField.value);
      }
    },
    //eslint-disable-next-line
    [changedField]
  );

  useEffect(
    function ue_setIsAnalysis_Map_or_Other() {
      if (!analysisType) return;

      const isMapAnalysis_ = mapAnalyses.includes(analysisType);
      const isOtherAnalysis_ = otherAnalyses.includes(analysisType);

      if (
        (isMapAnalysis_ && isOtherAnalysis_) ||
        (!isMapAnalysis_ && !isOtherAnalysis_)
      ) {
        throwCustomErrorInvalidValueOfVariable(
          "isMapAnalysis_ and isOtherAnalysis_",
          {
            value: {
              isMapAnalysis_: isMapAnalysis_,
              isOtherAnalysis_: isOtherAnalysis_,
            },
          },
          "useEffect- ue_setIsAnalysis_Map_or_Other",
          [
            `'isMapAnalysis' and 'isOtherAnalysis' cannot be both true or false`,
          ].join(" "),
          "DataAnalysisForm"
        );
      }

      setIsMapAnalysis(isMapAnalysis_);
      setIsOtherAnalysis(isOtherAnalysis_);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [analysisType]
  );

  useEffect(
    function useEffect_loadDataZources() {
      if (!analysisProject) return;

      /**
       * Load dataZources that belong to any of the selected projects and
       * that have status 'published' or 'hidden'. Update also the store state
       * surveyForms.
       * TODO: Create a lazy load process for forms!
       * Currently up to 500 forms are loaded from backend!
       */
      const history = props.history;
      const pagination = undefined;
      const filter = {
        dataAnalysisFormAllowedValuesForFormProjectID: [
          [analysisProject]?.join(","),
        ], // filter by selected projects id
        dataAnalysisFormAllowedValuesForFormStatus: [[1, 2].join(",")], // filter by status value 1 ('published') or 2 ('hidden')
        maxForms: 500,
      };
      const sorter = undefined;
      const entityUrl = "form";

      dispatch(listSurveyForms(history, pagination, filter, sorter, entityUrl));

      //set dataAnalysis geoArea by projectsId
      dispatch(
        getCurrentDataAnalysisGeoAreaByProjectsId(
          props.history,
          [analysisProject].join(",")
        )
      );
    },
    //eslint-disable-next-line
    [analysisProject]
  );

  useEffect(function useEffect_listFormFields_and_listProjeccts() {
    //list formFields and set formFields
    dispatch(listFormFields(props.history, false)); //projects are not listed with this action!!;

    //list active projects that hasForms
    const filter = { active: [true], hasForms: [true], maxProjects: [999] };
    dispatch(listProjects(props.history, undefined, filter));

    //eslint-disable-next-line
  }, []);

  useEffect(
    function ue_loadingData() {
      if (error) {
        const errorFunction = () => {
          if (typeof error == "string") {
            message.error(error).then(dispatch(clearDataAnalysisError()));
          } else if (Object.keys(error).includes("errors")) {
            error.errors.map((errorMessage) =>
              message
                .error(errorMessage.message)
                .then(dispatch(clearDataAnalysisError()))
            );
          } else if (Object.keys(error).includes("error")) {
            message
              .error(`Error: ${error.error}: ${error.message}`)
              .then(dispatch(clearDataAnalysisError()));
          }
        };
        errorFunction();
      }

      if (configView === undefined && analysisId) {
        dispatch(getConfigViewForUser(props.history, analysisId)).then(
          (data) => {
            if (data) {
              setConfigView(data.configViewUser);
              setIsReadyAnalysisFormData(true);
            } else {
              const location = {
                pathname: "/not-found",
                state: {
                  from: props.location.pathname,
                },
              };
              props.history.push(location);
            }
          }
        );
      }

      if (
        edit &&
        !dataAnalysisCreated &&
        dataAnalysis === null &&
        error === null &&
        configView !== undefined
      ) {
        dispatch(getDataAnalysis(props.history, analysisId)).then((data) => {
          if (data) {
            let dataCloned = JSONParseStringify(data); //https://www.w3docs.com/snippets/javascript/how-to-clone-a-javascript-object.html
            let mapDefaultLayer;

            if (view) {
              if (configView !== null) {
                //view and configView
                dataCloned.configView = JSONParseStringify(configView);

                //a created and already viewed dataAnalysis
                //---------------------------------------------
                if (dataCloned.configView?.dateRange) {
                  //transforming dateRange from string with dateFormat to moment
                  dataCloned.configView.dateRange[0] = moment(
                    dataCloned.configView.dateRange[0],
                    dateFormat
                  );
                  dataCloned.configView.dateRange[1] = moment(
                    dataCloned.configView.dateRange[1],
                    dateFormat
                  );
                }

                if (dataCloned.configView?.timeRange) {
                  //transforming timeRange from string with timeFormat to moment
                  dataCloned.configView.timeRange[0] = moment(
                    dataCloned.configView.timeRange[0],
                    timeFormat
                  );
                  dataCloned.configView.timeRange[1] = moment(
                    dataCloned.configView.timeRange[1],
                    timeFormat
                  );
                }

                if (dataCloned.type === "BarPlot") {
                  setMaxNumberOfBarsSlider({
                    min: dataCloned.configView.barPlotOptions
                      .maxNumberOfBars_min,
                    max: dataCloned.configView.barPlotOptions
                      .maxNumberOfBars_max,
                    step: dataCloned.configView.barPlotOptions
                      .maxNumberOfBars_step,
                    marks: dataCloned.configView?.barPlotOptions
                      ?.maxNumberOfBars_marks
                      ? JSON.parse(
                          dataCloned.configView.barPlotOptions
                            .maxNumberOfBars_marks
                        )
                      : null,
                  });
                }

                //subregions' levels in view if
                //configView is not null
                const dataZourceId =
                  dataCloned.configEdit.dataSeries[0].dataZource;
                if (
                  (dataCloned.type === "BarPlot" &&
                  dataCloned.configEdit.dataSeries[0].xaxes[0].variables[0]
                    .variables === `m${dataZourceId}.locations`) || dataCloned.type === "ChoroplethMap"
                ) {
                  const levels =
                    dataCloned["configView"]["locations"]["levels"];
                  if (Array.isArray(levels) && levels.length === 1) {
                    dataCloned["configView"]["locations"]["levels"] = levels[0];
                  } else {
                    console.log(
                      "There is some problem with data loading at : levels in DataAnalysisForm!"
                    );
                  }
                }

                if (
                  ["HeatMap", "BubbleMap", "SimplePointMap"].includes(
                    dataCloned.type
                  )
                ) {
                  mapDefaultLayer = dataCloned.configView.map.defaultLayer;
                  if (!Array.isArray(mapDefaultLayer)) {
                    dataCloned.configView.map.defaultLayer = [mapDefaultLayer];
                  }
                }
              } else {
                //view and not configView
                //setting condition.operator and condition.value fields to undefined in view mode
                const dataSeries =
                  dataCloned[configEditFieldName]["dataSeries"];
                const dataSeriesLength = dataSeries.length;
                if (dataSeriesLength > 0) {
                  for (
                    let nameDataSeries = 0;
                    nameDataSeries < dataSeriesLength;
                    nameDataSeries++
                  ) {
                    //setting conditions and evaluating if it has unique values or not
                    if (!!dataSeries[nameDataSeries]?.conditions) {
                      const conditions = dataSeries[nameDataSeries].conditions;
                      const conditionsLength = conditions.length;
                      if (conditionsLength > 0) {
                        for (let name = 0; name < conditionsLength; name++) {
                          const operatorField =
                            dataCloned[configEditFieldName]["dataSeries"][
                              nameDataSeries
                            ]["conditions"][name]["operator"];

                          //first variable to test
                          const operatorFieldWithOnlyOneValue =
                            operatorField?.length === 1;

                          //second variable to test
                          const valueField =
                            dataCloned[configEditFieldName]["dataSeries"][
                              nameDataSeries
                            ]["conditions"][name]["value"];
                          const valueFieldWithOnlyOneValue =
                            valueField !== undefined &&
                            valueField !== "" &&
                            ((Array.isArray(valueField) &&
                              valueField.length === 1) ||
                              !Array.isArray(valueField));

                          const areConditionsUnique =
                            operatorFieldWithOnlyOneValue &&
                            valueFieldWithOnlyOneValue;
                          if (areConditionsUnique) {
                            dataCloned[configEditFieldName]["dataSeries"][
                              nameDataSeries
                            ]["conditions"][name]["operator"] =
                              operatorField[0];
                            // dataCloned[configEditFieldName]["dataSeries"][
                            //   nameDataSeries
                            // ]["conditions"][name]["value"] = Array.isArray(
                            //   valueField
                            // )
                            //   ? valueField[0]
                            //   : valueField;
                          } else {
                            dataCloned[configEditFieldName]["dataSeries"][
                              nameDataSeries
                            ]["conditions"][name]["operator"] = undefined;
                            dataCloned[configEditFieldName]["dataSeries"][
                              nameDataSeries
                            ]["conditions"][name]["value"] = undefined;
                          }
                        }
                      }
                    }
                  }
                }

                //setting locations.geoRegions to undefined and
                //evaluating if it has unique values
                const regions =
                  dataCloned[configEditFieldName]["locations"]["regions"];

                if (
                  (Array.isArray(regions) && regions.length === 1) ||
                  !Array.isArray(regions)
                ) {
                  dataCloned[configEditFieldName]["locations"]["regions"] =
                    Array.isArray(regions) ? regions : [regions];
                } else {
                  dataCloned[configEditFieldName]["locations"]["regions"] =
                    undefined;
                  dataCloned[configEditFieldName]["locations"]["levels"] =
                    undefined;
                  dataCloned[configEditFieldName]["locations"][
                    "regionsSelectionMode"
                  ] = "selection";
                }

                //subregions' levels in view if
                //configView is null
                const dataZourceId =
                  dataCloned.configEdit.dataSeries[0].dataZource;
                if (
                  dataCloned.type === "BarPlot" &&
                  dataCloned.configEdit.dataSeries[0].xaxes[0].variables[0]
                    .variables === `m${dataZourceId}.locations`
                ) {
                  const levels =
                    dataCloned[configEditFieldName]["locations"]["levels"];
                  if (Array.isArray(levels) && levels.length === 1) {
                    dataCloned[configEditFieldName]["locations"]["levels"] =
                      levels[0]; //for TreeSelect with multiple={false} must be a single value
                  } else {
                    console.log(
                      "There is some problem with data loading at : 'levels' in DataAnalysisForm!"
                    );
                  }
                }

                //setting dateScale
                const dateScale = dataCloned[configEditFieldName]["dateScale"];
                if (dateScale?.length === 1) {
                  dataCloned[configEditFieldName]["dateScale"] = dateScale[0];
                } else {
                  dataCloned[configEditFieldName]["dateScale"] = undefined;
                }
              }

              if (dataCloned.type === "BubbleMap") {
                setRadiusDisplay(
                  dataCloned.configEdit.map["radius-showToUser"] ? null : "none"
                );
                setOpacityDisplay(
                  dataCloned.configEdit.map["opacity-showToUser"]
                    ? null
                    : "none"
                );
                setStrokeColorDisplay(
                  dataCloned.configEdit.map["stroke-color-showToUser"]
                    ? null
                    : "none"
                );
                setStrokeWidthDisplay(
                  dataCloned.configEdit.map["stroke-width-showToUser"]
                    ? null
                    : "none"
                );
                setStrokeOpacityDisplay(
                  dataCloned.configEdit.map["stroke-opacity-showToUser"]
                    ? null
                    : "none"
                );
              }
            } else {
              if (
                ["HeatMap", "BubbleMap", "SimplePointMap"].includes(
                  dataCloned.type
                )
              ) {
                mapDefaultLayer = dataCloned.configEdit.map.defaultLayer;
                if (!Array.isArray(mapDefaultLayer)) {
                  dataCloned.configEdit.map.defaultLayer = [mapDefaultLayer];
                }
              }
            }

            const configEditFieldValue = view
              ? configView !== null
                ? dataCloned.configView
                : dataCloned[configEditFieldName]
              : dataCloned[configEditFieldName];

            //this version of the application support only one project, but is using an array
            //in server due that the entity DataAnalysis has property 'projects'.
            //Here now is a validation for this condition.
            if (dataCloned.projects.length !== 1) {
              const errorMsg = [
                `Only one project can be used in dataAnalysis.`,
                `Something went wrong, because the server sent more than one project:`,
                `[${dataCloned.projects
                  .map((project) => project.id)
                  .join(", ")}].`,
                `Contact application's admin to update this analysis with id: ${dataCloned.id}!`,
              ].join(" ");
              console.log(errorMsg);
              message.error(errorMsg);
              throw new Error(errorMsg);
            }

            const dataClonedProject = dataCloned.projects.map(
              (project) => project.id
            )[0];

            form.setFieldsValue({
              name: dataCloned.name,
              description: dataCloned.description,
              type: dataCloned.type,
              projects: dataClonedProject,
              status: dataCloned.status,
              [configEditFieldName]: configEditFieldValue,
            });

            setDataClonedToFieldsValue({
              ...dataCloned,
              projects: dataClonedProject,
              [configEditFieldName]: configEditFieldValue,
            });

            const xVar =
              configEditFieldValue?.dataSeries?.[0]?.xaxes?.[0]?.variables?.[0]
                ?.variables;
            if (xVar) {
              setXVariableCurrentValue(xVar);
            }

            areAllFormFieldsJustFilledByCodeRef.current = true;

            setAnalysisProject(dataClonedProject);
            setAnalysisType(dataCloned.type);
          } else {
            const errorMsg = intl.formatMessage(
              {
                id: "msg.Error.DataAnalysisForm.dataNotFoundForLoadingAnalysis",
              },
              { analysisId: analysisId }
            );
            message.error(errorMsg);
            props.history.push("/configuration/dataAnalysis");
          }
        });
      }
    },
    // eslint-disable-next-line
    [error, dataAnalysisCreated, edit, configView, queryAction]
  );

  useEffect(
    function useEffect_dataAnalysisCreated_changed() {
      if (dataAnalysisCreated) {
        setDataAnalysisCreated(false);
        if (edit) {
          if (!view) {
            if (queryAction === null) {
              message.info(
                intl.formatMessage({
                  id: "label.dataAnalysis.dataAnalysis-updated",
                })
              );
            } else {
              setQueryAction(null);
              window.location.href = `/configuration/dataAnalysis/query/view/${analysisId}`;
            }
          } else {
            //here goes the code for setting analysisData, that is passed to graphics
            setLoadingAnalysis(true);
            dispatch(getDataForAnalysis(analysisId)).then((data) => {
              if (data) {
                setAnalysisRawData(data);
                //managing when no data is available
                if (data.data.length === 0) {
                  setLoadingAnalysis(false);
                  if (data.errors.length === 1) {
                    const errorMsg = intl.formatMessage(
                      { id: "Error.unexpectedError.Please.contactAdmin" },
                      { errorMsg: data.errors[0] }
                    );
                    message.error(errorMsg);
                  }
                }
                if (isQuery) scrollToAnchor("queryAnchor");
              }
            });
          }
        } else {
          /**
           * Load no dataZources and reset app store state 'surveyForms'
           */
          const history = props.history;
          const pagination = undefined;
          const filter = {
            project: -1, // no form can have this project id value, ensuring no form is loaded.
          };
          const sorter = undefined;
          const entityUrl = "form";

          dispatch(
            listSurveyForms(history, pagination, filter, sorter, entityUrl)
          );

          setAnalysisProject();
          setAnalysisType();
          form.resetFields();
          message.info(
            intl.formatMessage({
              id: "label.dataAnalysis.dataAnalysis-created",
            })
          );
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dataAnalysisCreated]
  );

  useEffect(
    function useEffect_setSelectedDataZources() {
      //initialize selectedDataZources
      //it occurs when edit or view analysis and when configEdit.dataSeries has some value
      //it makes asynch requests to api, and the value is set by passing setSelectedDataZources hook.
      if (edit && configEdit?.dataSeries) {
        getSelectedDataZourcesFromAPI_using_getSurveyForm(
          configEdit.dataSeries,
          setSelectedDataZources,
          getSurveyForm,
          dispatch,
          intl,
          "dataZource"
        );

        getSelectedDataZourcesFromAPI_using_getSurveyForm(
          configEdit.dataSeries,
          setSelectedAdditionalDataZources,
          getSurveyForm,
          dispatch,
          intl,
          "additionalDataZources"
        );
      }
    },
    //eslint-disable-next-line
    [configEdit?.dataSeries, edit]
  );

  useEffect(function useEffect_setQueryRefs() {
    mainQueryRef.current?.getEditor().root.setAttribute("spellcheck", false);
    popupQueryRef.current?.getEditor().root.setAttribute("spellcheck", false);
  }, []);

  useEffect(
    function useEffect_setValueMainQuery() {
      if (analysisRawData?.query) {
        const queriesNumber = analysisRawData.query.length;
        let textToInsertArray = [];
        if (queriesNumber > 1) {
          textToInsertArray.push(
            "-- ".concat(
              "There are ",
              queriesNumber,
              " queries",
              " in this document"
            )
          );
          textToInsertArray.push(
            "-- ".concat("******************************************")
          );
        } else {
          textToInsertArray.push(
            "-- ".concat(
              "There is ",
              queriesNumber,
              " query",
              " in this document"
            )
          );
          textToInsertArray.push(
            "-- ".concat("**************************************")
          );
        }

        let queryFor;
        for (let i = 0; i < queriesNumber; i++) {
          if (analysisType === "TimeSeries" || analysisType === "BarPlot") {
            queryFor = analysisRawData.configEdit.dataSeries[i].yaxes
              .map((yaxis) => yaxis.title)
              .join(", ");
            textToInsertArray.push(
              "-- ".concat(
                "Query for DataSeries with yAxis Title / yAxes Titles: ",
                queryFor
              )
            );
          } else if (
            analysisType === "HeatMap" ||
            analysisType === "BubbleMap" ||
            ["CategoricalPointMap", "SimplePointMap"].includes(analysisType)
          ) {
            queryFor = analysisRawData.configEdit.dataSeries[i].variables
              .map((varObj) => varObj.alias)
              .join(", ");
            textToInsertArray.push(
              "-- ".concat(
                "Query for DataSeries with Variable Alias / Variables Aliases: ",
                queryFor
              )
            );
          }
          textToInsertArray.push(analysisRawData.query[i]);
          textToInsertArray.push(
            "-- ".concat("******************************************")
          );
        }

        const textToInsert = textToInsertArray.join("\n");
        const editorDelta = {
          //inserting a Delta as value to show text with format
          ops: [{ insert: textToInsert }],
        };
        setValueMainQuery(editorDelta);
      }
      //eslint-disable-next-line
    },
    //eslint-disable-next-line
    [analysisRawData]
  );

  useEffect(() => {
    if (reactFormDataUpdateStatus.status === false) {
      message.error(reactFormDataUpdateStatus.error.message);
      dispatch(clearUpdateReactFormDataDataAnalysisError());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reactFormDataUpdateStatus]);

  //-----------------
  //effects - end
  //-----------------

  //-----------------------
  //jsx variables - start
  //-----------------------
  const breadcrumbHome = (
    <Breadcrumb.Item>
      <HomeOutlined />
    </Breadcrumb.Item>
  );

  const breadcrumbAnalysisConfigurationBasic = (
    <>
      <Breadcrumb.Item>
        <FormattedMessage
          id="menu.configuration"
          defaultMessage="Configuration"
        />
      </Breadcrumb.Item>
      <Breadcrumb.Item>
        <FormattedMessage
          id="menu.dataAnalysis"
          defaultMessage="Data Analysis"
        />
      </Breadcrumb.Item>
    </>
  );

  const breadcrumbAnalysisConfigurationCasesEdit = useMemo(
    () => {
      if (!dataAnalysis?.name) return null;

      let breadcrumb = null;
      if (view) {
        breadcrumb = (
          <>
            <Breadcrumb.Item>
              <FormattedMessage
                id={intl.formatMessage({ id: "label.dataAnalysis.view" })}
                defaultMessage="View"
              />
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              {extractProjectFormsAndName(dataAnalysis.name)}
            </Breadcrumb.Item>
          </>
        );
      } else if (!view && edit) {
        breadcrumb = (
          <>
            <Breadcrumb.Item>
              <FormattedMessage
                id={intl.formatMessage({ id: "label.dataAnalysis.edit" })}
                defaultMessage="Edit"
              />
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              {extractProjectFormsAndName(dataAnalysis.name)}
            </Breadcrumb.Item>
          </>
        );
      }

      return breadcrumb;
    },
    //eslint-disable-next-line
    [dataAnalysis?.name, view, edit]
  );

  const breadcrumbAnalysisConfigurationCasesNew = useMemo(
    () => {
      let breadcrumb = null;
      if (isNew) {
        breadcrumb = (
          <Breadcrumb.Item>
            <FormattedMessage
              id={intl.formatMessage({ id: "label.new" })}
              defaultMessage="New"
            />
          </Breadcrumb.Item>
        );
      }

      return breadcrumb;
    },
    //eslint-disable-next-line
    [isNew]
  );

  const breadcrumbQuery = useMemo(
    () =>
      isQuery && (
        <Breadcrumb.Item>
          <FormattedMessage
            id="label.dataAnalysis.Query"
            defaultMessage="Query"
          />
        </Breadcrumb.Item>
      ),
    [isQuery]
  );

  const breadcrumbAnalysisConfig = useMemo(
    () => {
      let breadcrumb = null;

      if (edit && !!dataAnalysis?.name) {
        breadcrumb = (
          <>
            {breadcrumbHome}
            {breadcrumbAnalysisConfigurationBasic}
            {breadcrumbAnalysisConfigurationCasesEdit}
            {breadcrumbQuery}
          </>
        );
      } else if (isNew) {
        breadcrumb = (
          <>
            {breadcrumbHome}
            {breadcrumbAnalysisConfigurationBasic}
            {breadcrumbAnalysisConfigurationCasesNew}
            {breadcrumbQuery}
          </>
        );
      }

      return breadcrumb;
    },
    //eslint-disable-next-line
    [dataAnalysis?.name, edit, isNew, isQuery]
  );
  const breadcrumbAnalysisNotConfig = useMemo(
    () => {
      if (!dataAnalysis?.name) return null;

      let idPart, msgDefault;
      let breadcrumb = null;

      if (isTimeSeries) {
        idPart = "TimeSeries";
        msgDefault = "Time Series";
      }
      if (isBarPlots) {
        idPart = "BarPlots";
        msgDefault = "Bar Plots";
      }
      if (isHeatMaps) {
        idPart = "HeatMaps";
        msgDefault = "Heat Maps";
      }
      if (isPointMaps) {
        idPart = "PointMaps";
        msgDefault = "Point Maps";
      }
      if (isChoroplethMaps) {
        idPart = "ChoroplethMaps";
        msgDefault = "Choropleth Maps";
      }
      if (isBubbleMaps) {
        idPart = "BubbleMaps";
        msgDefault = "Bubble Maps";
      }

      if (idPart) {
        breadcrumb = (
          <>
            {breadcrumbHome}
            <Breadcrumb.Item>
              <FormattedMessage id="menu.Analysis" defaultMessage="Analysis" />
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <FormattedMessage
                id={"menu.Analysis." + idPart}
                defaultMessage={msgDefault}
              />
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              {extractProjectFormsAndName(dataAnalysis.name)}
            </Breadcrumb.Item>
          </>
        );
      }

      return breadcrumb;
    },
    //eslint-disable-next-line
    [
      dataAnalysis?.name,
      isTimeSeries,
      isBarPlots,
      isPointMaps,
      isBubbleMaps,
      isChoroplethMaps,
      isHeatMaps,
    ]
  );

  const suspenseEdit = useMemo(
    () => (
      <>
        <Suspense fallback={<Skeleton active={true} />}>
          <Form
            layout={view ? "vertical" : "horizontal"}
            name="dataAnalysis_form"
            size="large"
            labelCol={{ span: 3 }}
            wrapperCol={{ span: 14 }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            onValuesChange={handleValuesChange}
            onFieldsChange={handleFieldsChange}
            initialValues={{ status: "0" }}
            form={form}
          >
            <Form.Item
              name="status"
              label={intl.formatMessage({
                id: "label.dataAnalysis.status",
              })}
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({
                    id: "msg.input-required.Analysis.status",
                  }),
                },
              ]}
              hidden={view}
            >
              <Select
                allowClear={false}
                options={statusOptions}
                placeholder={intl.formatMessage({
                  id: "label.dataAnalysis.status.select",
                })}
                disabled={!statusOptions || statusOptions?.length === 0}
              />
            </Form.Item>
            <Form.Item
              name="name"
              label={intl.formatMessage({
                id: "label.dataAnalysis.name",
              })}
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({
                    id: "msg.input-required.Analysis.name",
                  }),
                },
              ]}
              hidden={view}
              validateTrigger={"onBlur"}
            >
              <Input allowClear={true} />
            </Form.Item>
            <Form.Item
              name="description"
              label={intl.formatMessage({
                id: "label.dataAnalysis.description",
              })}
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({
                    id: "msg.input-required.Analysis.description",
                  }),
                },
              ]}
              hidden={view}
              validateTrigger={"onBlur"}
            >
              <Input allowClear={true} />
            </Form.Item>
            <Form.Item
              name="projects"
              label={intl.formatMessage({
                id: "label.dataAnalysis.project",
              })}
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({
                    id: "msg.input-required.Analysis.projects",
                  }),
                },
              ]}
              hidden={view}
            >
              <Select
                allowClear={true}
                options={projectsSelectOptionsMemo}
                placeholder={
                  !projectsSelectOptionsMemo ? (
                    <LoadingProjectOptions />
                  ) : (
                    intl.formatMessage({
                      id: "label.dataAnalysis.projects.select",
                    })
                  )
                }
              />
            </Form.Item>
            <Form.Item
              name="type"
              label={intl.formatMessage({
                id: "label.dataAnalysis.type",
              })}
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({
                    id: "msg.input-required.Analysis.type",
                  }),
                },
              ]}
              hidden={view}
            >
              <TreeSelect
                treeDefaultExpandAll={true}
                allowClear={true}
                multiple={false}
                treeData={treeDataAnalysisTypeField}
                placeholder={intl.formatMessage({
                  id: "label.dataAnalysis.type.select",
                })}
                disabled={
                  !treeDataAnalysisTypeField ||
                  treeDataAnalysisTypeField?.length === 0
                }
              />
            </Form.Item>
            <Form.Item
              name={["configEdit", "default"]}
              label={intl.formatMessage({
                id: "label.dataAnalysis.type.default",
              })}
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({
                    id: "msg.input-required.Analysis.type.default",
                  }),
                },
              ]}
              hidden={view || !configEdit?.default}
              valuePropName="checked"
              initialValue={false}
            >
              <Checkbox disabled />
            </Form.Item>
            <Form.Item
              labelCol={{ span: 5 }}
              name={["configEdit", "defaultDataAnalysesSetID"]}
              label={intl.formatMessage({
                id: "label.dataAnalysis.defaultDataAnalysesSetID",
              })}
              rules={[
                {
                  required: configEdit?.default,
                  message: intl.formatMessage({
                    id: "msg.input-required.Analysis.defaultDataAnalysesSetID",
                  }),
                },
              ]}
              hidden={view || !configEdit?.default}
            >
              <Input readOnly style={{ width: "250px" }} />
            </Form.Item>
            <FormItemsAccordingToAnalysisType />
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                loading={loading || loadingAnalysis}
                disabled={!wasFormJustRendered || !dataZources}
                id={"form-submit-button"}
                className={view ? "form-submit-button-view" : null}
              >
                {isQuery ? (
                  <FormattedMessage
                    id="label.dataAnalysis.getQuery"
                    defaultMessage="Get Query!"
                  />
                ) : view ? (
                  <FormattedMessage
                    id="label.dataAnalysis.view.applyFilters"
                    defaultMessage="Apply Filters"
                  />
                ) : edit ? (
                  <FormattedMessage id="label.update" defaultMessage="Update" />
                ) : (
                  <FormattedMessage id="label.submit" defaultMessage="Submit" />
                )}
              </Button>
            </Form.Item>
          </Form>
        </Suspense>
      </>
    ),
    //eslint-disable-next-line
    [
      edit,
      isQuery,
      loadingAnalysis,
      loading,
      dataZources,
      view,
      wasFormJustRendered,
    ]
  );

  const suspenseNew = useMemo(
    () => (
      <>
        <Form
          layout={view ? "vertical" : "horizontal"}
          name="dataAnalysis_form"
          size="large"
          labelCol={{ span: 3 }}
          wrapperCol={{ span: 14 }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          onValuesChange={handleValuesChange}
          onFieldsChange={handleFieldsChange}
          initialValues={{ status: "0" }}
          form={form}
        >
          <Form.Item
            name="status"
            label={intl.formatMessage({
              id: "label.dataAnalysis.status",
            })}
            rules={[
              {
                required: true,
                message: intl.formatMessage({
                  id: "msg.input-required.Analysis.status",
                }),
              },
            ]}
            hidden={view}
          >
            <Select
              allowClear={false}
              options={statusOptions}
              placeholder={intl.formatMessage({
                id: "label.dataAnalysis.status.select",
              })}
              disabled={!statusOptions || statusOptions?.length === 0}
            />
          </Form.Item>
          <Form.Item
            name="name"
            label={intl.formatMessage({
              id: "label.dataAnalysis.name",
            })}
            rules={[
              {
                required: true,
                message: intl.formatMessage({
                  id: "msg.input-required.Analysis.name",
                }),
              },
            ]}
            hidden={view}
          >
            <Input allowClear={true} />
          </Form.Item>
          <Form.Item
            name="description"
            label={intl.formatMessage({
              id: "label.dataAnalysis.description",
            })}
            rules={[
              {
                required: true,
                message: intl.formatMessage({
                  id: "msg.input-required.Analysis.description",
                }),
              },
            ]}
            hidden={view}
          >
            <Input allowClear={true} />
          </Form.Item>
          <Form.Item
            name="projects"
            label={intl.formatMessage({
              id: "label.dataAnalysis.project",
            })}
            rules={[
              {
                required: true,
                message: intl.formatMessage({
                  id: "msg.input-required.Analysis.projects",
                }),
              },
            ]}
            hidden={view}
          >
            <Select
              allowClear={true}
              options={projectsSelectOptionsMemo}
              placeholder={
                !projectsSelectOptionsMemo ? (
                  <LoadingProjectOptions />
                ) : (
                  intl.formatMessage({
                    id: "label.dataAnalysis.projects.select",
                  })
                )
              }
            />
          </Form.Item>
          <Form.Item
            name="type"
            label={intl.formatMessage({
              id: "label.dataAnalysis.type",
            })}
            rules={[
              {
                required: true,
                message: intl.formatMessage({
                  id: "msg.input-required.Analysis.type",
                }),
              },
            ]}
            hidden={view}
          >
            <TreeSelect
              treeDefaultExpandAll={true}
              allowClear={true}
              multiple={false}
              treeData={treeDataAnalysisTypeField}
              placeholder={intl.formatMessage({
                id: "label.dataAnalysis.type.select",
              })}
              disabled={
                !treeDataAnalysisTypeField ||
                treeDataAnalysisTypeField?.length === 0
              }
            />
          </Form.Item>
          <Suspense fallback={<Skeleton active={true} />}>
            <FormItemsAccordingToAnalysisType />
          </Suspense>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={loading || loadingAnalysis}
              disabled={!dataZources}
              id={"form-submit-button"}
              className={view ? "form-submit-button-view" : null}
            >
              {isQuery ? (
                <FormattedMessage
                  id="label.dataAnalysis.getQuery"
                  defaultMessage="Get Query!"
                />
              ) : view ? (
                <FormattedMessage
                  id="label.dataAnalysis.view.applyFilters"
                  defaultMessage="Apply Filters"
                />
              ) : edit ? (
                <FormattedMessage id="label.update" defaultMessage="Update" />
              ) : (
                <FormattedMessage id="label.submit" defaultMessage="Submit" />
              )}
            </Button>
          </Form.Item>
        </Form>
      </>
    ),
    //eslint-disable-next-line
    [
      edit,
      isQuery,
      loadingAnalysis,
      loading,
      dataZources,
      view,
      projectsSelectOptionsMemo,
    ]
  );
  //-----------------------
  //jsx variables - end
  //-----------------------

  //jsx
  return (
    <StrictMode>
      <DataAnalysisFormContext.Provider
        value={{
          aliasMaxLength: 25, //Constant. The maximum number of characters of variables' alias.
          analysisId: analysisId,
          formMode: formMode,
          analysisProject:
            analysisProject,
          analysisRawData: analysisRawData,
          analysisType: analysisType,
          antDividerBorderTopColor: antDividerBorderTopColor,
          areAllFormFieldsJustFilledByCodeRef:
            areAllFormFieldsJustFilledByCodeRef,
          categoriesByVariables: categoriesByVariables,
          changedField: changedField,
          optionsConditionsLogicalOperator: optionsConditionsLogicalOperator,
          configEdit: configEdit,
          configEditFieldName: configEditFieldName,
          configView: configView,
          dataZources: dataZources,
          dateFormat: dateFormat,
          timeFormat: timeFormat,
          edit: edit,
          form: form,
          formFields: formFields,
          getFieldPath: getFieldPath,
          getFieldPathString: getFieldPathString,
          getIsFieldChanged: getIsFieldChanged,
          getSurveyForm: getSurveyForm,
          history: props.history,
          isNew: isNew,
          isQuery: isQuery,
          isXDateType: isXDateType,
          isLocationsBarPlotXVariable: isLocationsBarPlotXVariable,
          isMapAnalysis: isMapAnalysis,
          isOtherAnalysis: isOtherAnalysis,
          loadingAnalysis: loadingAnalysis,
          mapAnalyses: mapAnalyses,
          maxNumberOfBarsSlider: maxNumberOfBarsSlider,
          opacityDisplay: opacityDisplay,
          otherAnalyses: otherAnalyses,
          plotHeight: plotHeight,
          radiusDisplay: radiusDisplay,
          rootPathConfigEdit: rootPathConfigEdit, //only for FormListDataSeries and FormListDataSeriesMaps
          scrollToAnchor: scrollToAnchor,
          selectedAdditionalDataZources: selectedAdditionalDataZources,
          selectedDataZources: selectedDataZources,
          setCategoriesByVariables: setCategoriesByVariables,
          setLoadingAnalysis: setLoadingAnalysis,
          setMaxNumberOfBarsSlider: setMaxNumberOfBarsSlider,
          setOpacityDisplay: setOpacityDisplay,
          setRadiusDisplay: setRadiusDisplay,
          setSelectedAdditionalDataZources: setSelectedAdditionalDataZources,
          setSelectedDataZources: setSelectedDataZources,
          setStrokeColor: setStrokeColor,
          setStrokeColorDisplay: setStrokeColorDisplay,
          setStrokeOpacityDisplay: setStrokeOpacityDisplay,
          setStrokeWidthDisplay: setStrokeWidthDisplay,
          setValuePopupQuery: setValuePopupQuery,
          strokeColor: strokeColor,
          strokeColorDisplay: strokeColorDisplay,
          strokeOpacityDisplay: strokeOpacityDisplay,
          strokeWidthDisplay: strokeWidthDisplay,
          unexpectedErrorMsg: unexpectedErrorMsg,
          referenceGeoAreas: referenceGeoAreas,
          view: view,
        }}
      >
        <Suspense fallback={<Skeleton active={true} />}>
          <Layout className="site-layout">
            <Content style={{ margin: "0 16px" }}>
              <Breadcrumb style={{ margin: "10px 0" }}>
                {isConfig
                  ? breadcrumbAnalysisConfig
                  : breadcrumbAnalysisNotConfig}
              </Breadcrumb>

              {isReadyAnalysisFormData ? (
                <>
                  <div
                    className="site-layout-background"
                    style={{ padding: 24, minHeight: 360 }}
                  >
                    <Card
                      type="inner"
                      title={
                        isQuery ? (
                          <FormattedMessage
                            id={"label.dataAnalysis.view.Query"}
                          />
                        ) : view ? (
                          analysisType ? (
                            <FormattedMessage
                              id={"label.dataAnalysis.view." + analysisType}
                            />
                          ) : (
                            <FormattedMessage id={"label.dataAnalysis.view"} />
                          )
                        ) : edit ? (
                          <FormattedMessage
                            id="label.dataAnalysis.edit-dataAnalysis"
                            defaultMessage="Edit Data Analysis"
                          />
                        ) : (
                          <FormattedMessage
                            id="label.dataAnalysis.create-dataAnalysis"
                            defaultMessage="Create a DataAnalysis"
                          />
                        )
                      }
                      extra={(() => {
                        let linkTo;
                        if (isQuery) {
                          return (
                            <Button type={"link"} onClick={handleBackFromQuery}>
                              <FormattedMessage
                                id="label.return"
                                defaultMessage="Return"
                              />
                            </Button>
                          );
                        }
                        if (isConfig && !isQuery)
                          linkTo = "/configuration/dataAnalysis";
                        if (isTimeSeries) linkTo = "/analysis/time-series";
                        if (isBarPlots) linkTo = "/analysis/bar-plots";
                        if (isHeatMaps) linkTo = "/analysis/heat-maps";
                        if (isPointMaps) linkTo = "/analysis/point-maps";
                        if (isChoroplethMaps)
                          linkTo = "/analysis/choropleth-maps";
                        if (isBubbleMaps) linkTo = "/analysis/bubble-maps";
                        return (
                          <Link to={{ pathname: linkTo }}>
                            <FormattedMessage
                              id="label.return"
                              defaultMessage="Return"
                            />
                          </Link>
                        );
                      })()}
                    >
                      {
                        //plot
                        !isQuery &&
                          view &&
                          analysisRawData?.data?.length > 0 &&
                          !!analysisType && (
                            <Suspense fallback={<Skeleton active={true} />}>
                              <Row gutter={16} style={{ marginBottom: "20px" }}>
                                <Col span={24}>
                                  <Card id={"plots"}>
                                    <Col
                                      style={{
                                        height:
                                          analysisType === "BarPlot"
                                            ? plotHeight - 25
                                            : plotHeight,
                                        padding: 0,
                                      }}
                                    >
                                      {plotAnalysis()}
                                    </Col>
                                  </Card>
                                </Col>
                              </Row>
                            </Suspense>
                          )
                      }
                      {isNew ? suspenseNew : suspenseEdit}
                      {isConfig ? (
                        <div
                          hidden={!(view && !!analysisRawData?.query)}
                          id={"queryAnchor"}
                        >
                          <ReactQuill
                            theme="snow"
                            id={"editorMainQuery"}
                            value={valueMainQuery}
                            onChange={setValueMainQuery}
                            spellCheck={false}
                            modules={{ toolbar: null }}
                            format={null}
                            style={{ width: "100%" }}
                            ref={mainQueryRef}
                          />
                          {[
                            "CategoricalPointMap",
                            "SimplePointMap",
                            "ChoroplethMap",
                            "HeatMap",
                            "BubbleMap",
                          ].includes(analysisType) ? (
                            <ReactQuill
                              theme="snow"
                              id={"editorPopupQuery"}
                              value={valuePopupQuery}
                              onChange={setValuePopupQuery}
                              spellCheck={false}
                              modules={{ toolbar: null }}
                              format={null}
                              style={{ width: "100%" }}
                              ref={popupQueryRef}
                            />
                          ) : null}
                        </div>
                      ) : null}
                    </Card>
                  </div>
                  {edit && !view ? (
                    <div
                      id={"query-bar"}
                      style={{
                        position: "fixed",
                        bottom: "25px",
                        right: "40px",
                        border: "1px solid #e6e6ff",
                        backgroundColor: "#fafafa",
                        borderRadius: "6px",
                      }}
                    >
                      <Button
                        icon={<EyeTwoTone twoToneColor="#364fc7" />}
                        title={intl.formatMessage({
                          id: "label.dataAnalysis.show-query",
                        })}
                        style={{
                          margin: "10px 10px 10px 10px",
                          borderRadius: "6px",
                        }}
                        onClick={handleShowQuery}
                      />
                    </div>
                  ) : null}
                </>
              ) : null}
            </Content>
            <Footer style={{ textAlign: "center" }}>
              ©{new Date().getFullYear()}
            </Footer>
          </Layout>
        </Suspense>
      </DataAnalysisFormContext.Provider>
    </StrictMode>
  );
};

export default DataAnalysisForm;
