import axiosInstance from "../../utils/axiosInstance";
import { DATA_ANALYSIS_ACTION_TYPES } from "../typesAction";

export const setLoading = () => {
  return { type: DATA_ANALYSIS_ACTION_TYPES.LOAD_DATA_ANALYSIS };
};

export const clearLoading = () => {
  return { type: DATA_ANALYSIS_ACTION_TYPES.CLEAR_LOAD_DATA_ANALYSIS };
};

export const clearDataAnalysisError = () => {
  return { type: DATA_ANALYSIS_ACTION_TYPES.CLEAR_DATA_ANALYSIS_ERRORS };
};

export const listDataAnalysis =
  (history, pagination, filters = {}, sorter) =>
  async (dispatch) => {
    try {
      dispatch(setLoading());
      // Prepare all query parameters
      let queryParameters = {};
      Object.keys(filters).forEach((key) => {
        if (filters[key] !== null) {
          queryParameters[key] = filters[key];
        }
      });

      if (sorter !== undefined) {
        queryParameters["sort"] = sorter["field"];
        queryParameters["order"] =
          sorter["order"] === "descend" ? "desc" : "asc";
      }

      if (pagination !== undefined) {
        queryParameters["offset"] =
          (pagination.current - 1) * pagination.pageSize;
        queryParameters["max"] = pagination.pageSize;
      }

      let params = new URLSearchParams();
      Object.keys(queryParameters).forEach((key) => {
        const value = queryParameters[key];
        if (Array.isArray(value)) {
          value.forEach((singleValue) => params.append(key, singleValue));
        } else {
          params.append(key, value);
        }
      });

      // Send request and get data
      const { data } = await axiosInstance(history, dispatch).get(
        "/dataAnalysis",
        { params }
      );
      // Update state
      dispatch({
        type: DATA_ANALYSIS_ACTION_TYPES.LIST_DATA_ANALYSIS,
        payload: data,
      });
    } catch (err) {
      console.log(err);
      dispatch({
        type: DATA_ANALYSIS_ACTION_TYPES.ERROR_DATA_ANALYSIS,
        payload: err.message,
      });
    }
  };

export const addDataAnalysis =
  (history, dataAnalysis, setDataAnalysisCreated) => async (dispatch) => {
    try {
      dispatch(setLoading());
      // Send request and get data
      const { data } = await axiosInstance(history, dispatch).post(
        "/dataAnalysis",
        dataAnalysis
      );
      // Update state
      dispatch({
        type: DATA_ANALYSIS_ACTION_TYPES.ADD_DATA_ANALYSIS,
        payload: data,
      });
      setDataAnalysisCreated(true);
    } catch (err) {
      console.log(err);
      const error = await err.response.data;
      dispatch({
        type: DATA_ANALYSIS_ACTION_TYPES.ERROR_DATA_ANALYSIS,
        payload: error,
      });
    }
  };

export const getDataAnalysis = (history, id) => async (dispatch) => {
  try {
    dispatch(setLoading());

    // Send requests and get responses
    const { data } = await axiosInstance(history, dispatch).get(
      `/dataAnalysis/${id}`
    );

    // Update state
    dispatch({
      type: DATA_ANALYSIS_ACTION_TYPES.SHOW_DATA_ANALYSIS,
      payload: data,
    });
    return data;
  } catch (err) {
    console.log(err);
    dispatch({
      type: DATA_ANALYSIS_ACTION_TYPES.ERROR_DATA_ANALYSIS,
      payload: err.message,
    });
  }
};

export const editDataAnalysis =
  (history, id, dataAnalysis, setDataAnalysisCreated) => async (dispatch) => {
    try {
      dispatch(setLoading());
      // Send request and get data
      const { data } = await axiosInstance(history, dispatch).patch(
        `/dataAnalysis/${id}`,
        dataAnalysis
      );

      // Update state
      dispatch({
        type: DATA_ANALYSIS_ACTION_TYPES.EDIT_DATA_ANALYSIS,
        payload: data,
      });
      setDataAnalysisCreated(true);
    } catch (err) {
      console.log(err);
      const error = await err.response.data;
      dispatch({
        type: DATA_ANALYSIS_ACTION_TYPES.ERROR_DATA_ANALYSIS,
        payload: error,
      });
    }
  };

export const deleteDataAnalysis = (history, id) => async (dispatch) => {
  try {
    dispatch(setLoading());
    // Send request
    await axiosInstance(history, dispatch).delete(`/dataAnalysis/${id}`);
    // Update state
    dispatch({
      type: DATA_ANALYSIS_ACTION_TYPES.DELETE_DATA_ANALYSIS,
      payload: id,
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: DATA_ANALYSIS_ACTION_TYPES.ERROR_DATA_ANALYSIS,
      payload: err.message,
    });
  }
};

export const viewDataAnalysis = (history, id, filters) => async (dispatch) => {
  try {
    dispatch(setLoading());

    //setting params
    let queryParameters = {};
    Object.keys(filters).map(
      (key) =>
        (queryParameters[key] =
          filters[key] == null ? filters[key] : filters[key][0])
    );
    // Spread the parameters
    const params = {
      ...queryParameters,
    };

    // Send requests and get responses
    const { data } = await axiosInstance(history, dispatch).get(
      `/dataAnalysis/view/${id}`,
      { params }
    );

    // Update state
    dispatch({
      type: DATA_ANALYSIS_ACTION_TYPES.VIEW_DATA_ANALYSIS,
      payload: data,
    });
    return data;
  } catch (err) {
    console.log(err);
    dispatch({
      type: DATA_ANALYSIS_ACTION_TYPES.ERROR_DATA_ANALYSIS,
      payload: err.message,
    });
  }
};

export const getDataDateRange = (id) => async (dispatch) => {
  try {
    // Send requests and get responses
    const { data } = await axiosInstance().get(
      `/dataAnalysis/${id}/getDataDateRange`
    );
    return data;
  } catch (err) {
    console.log(err);
    return { data: [], errors: err?.toString() };
  }
};

export const updateReactFormData =
  (data, setReactFormDataUpdateStatus) => async (dispatch) => {
    try {
      const validKeys = [
        "changedField",
        "changedFields",
        "allFields",
        "changedValues",
        "allValues",
      ];
      if (!Object.keys(data).every((key) => validKeys.includes(key))) {
        const error = {
          message:
            "Invalid data to update react form data in store! Check browser console output!",
          data: data,
          validKeys: validKeys,
        };
        dispatch({
          type: DATA_ANALYSIS_ACTION_TYPES.ERROR_UPDATE_REACT_FORM_DATA_DATA_ANALYSIS,
          payload: error,
        });
        setReactFormDataUpdateStatus({ status: false, error: error });
      } else {
        dispatch({
          type: DATA_ANALYSIS_ACTION_TYPES.UPDATE_REACT_FORM_DATA_DATA_ANALYSIS,
          payload: data,
        });
        setReactFormDataUpdateStatus({ status: true, error: null });
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: DATA_ANALYSIS_ACTION_TYPES.ERROR_UPDATE_REACT_FORM_DATA_DATA_ANALYSIS,
        payload: err,
      });
      setReactFormDataUpdateStatus({
        status: false,
        error: { message: err.toString() },
      });
    }
  };

export const clearUpdateReactFormDataDataAnalysisError = () => {
  return {
    type: DATA_ANALYSIS_ACTION_TYPES.CLEAR_UPDATE_REACT_FORM_DATA_DATA_ANALYSIS_ERRORS,
  };
};

export const getCurrentDataAnalysisGeoAreaByProjectsId =
  (history, projectsId) => async (dispatch) => {
    try {
      dispatch(setLoading());

      //params
      const params = {
        projectsId: projectsId,
      };

      // Send request and get data
      const { data } = await axiosInstance(history, dispatch).get(
        "/geolevel/data_analysis_geo_area",
        { params }
      );
      dispatch({
        type: DATA_ANALYSIS_ACTION_TYPES.GET_CURRENT_DATA_ANALYSIS_GEO_AREA,
        payload: data,
      });
    } catch (err) {
      console.log(err);
      dispatch({
        type: DATA_ANALYSIS_ACTION_TYPES.ERROR_DATA_ANALYSIS,
        payload: err.message,
      });
    }
  };

//default dataAnalyses transaction
export const addDefaultDataAnalysesTransaction =
  async (history, dataAnalyses, setDataAnalysisCreated) => async (dispatch) => {
    try {
      dispatch(setLoading());
      // Send request and get data
      const { data } = await axiosInstance(history, dispatch).post(
        "/dataAnalysis/addDefault",
        dataAnalyses
      );
      // Update state
      dispatch({
        type: DATA_ANALYSIS_ACTION_TYPES.ADD_DEFAULT_DATA_ANALYSES_TRANSACTION,
        payload: data,
      });

      if (setDataAnalysisCreated) setDataAnalysisCreated(true);
    } catch (err) {
      console.log(err);
      const error = await err.response.data;
      dispatch({
        type: DATA_ANALYSIS_ACTION_TYPES.ERROR_DEFAULT_DATA_ANALYSES_TRANSACTION,
        payload: error,
      });
    }
  };

export const editDefaultDataAnalysesTransaction =
  async (history, defaultDataAnalysesUpdates, setDataAnalysisCreated) =>
  async (dispatch) => {
    try {
      dispatch(setLoading());
      // Send request and get data
      const { data } = await axiosInstance(history, dispatch).patch(
        "/dataAnalysis/editDefault",
        defaultDataAnalysesUpdates
      );
      // Update state
      dispatch({
        type: DATA_ANALYSIS_ACTION_TYPES.EDIT_DEFAULT_DATA_ANALYSES_TRANSACTION,
        payload: data,
      });

      if (setDataAnalysisCreated) setDataAnalysisCreated(true);
    } catch (err) {
      console.log(err);
      const error = await err.response.data; //TODO: why to write this code?
      dispatch({
        type: DATA_ANALYSIS_ACTION_TYPES.ERROR_DEFAULT_DATA_ANALYSES_TRANSACTION,
        payload: error,
      });
    }
  };

export const deleteDefaultDataAnalysesTransaction =
  (history, ids) => async (dispatch) => {
    try {
      dispatch(setLoading());
      // Send request
      await axiosInstance(history, dispatch).delete(
        `/dataAnalysis/deleteDefault/${ids}`
      );
      // Update state
      dispatch({
        type: DATA_ANALYSIS_ACTION_TYPES.DELETE_DEFAULT_DATA_ANALYSES_TRANSACTION,
        payload: ids,
      });
    } catch (err) {
      console.log(err);
      dispatch({
        type: DATA_ANALYSIS_ACTION_TYPES.ERROR_DEFAULT_DATA_ANALYSES_TRANSACTION,
        payload: err.message,
      });
    }
  };

//??
export const listDefaultDataAnalysesTransaction = async (ids) => {
  return {
    type: DATA_ANALYSIS_ACTION_TYPES.LIST_DEFAULT_DATA_ANALYSES_TRANSACTION,
    payload: ids,
  };
};

//?
export const getDefaultDataAnalysesTransaction = async (ids) => {
  return {
    type: DATA_ANALYSIS_ACTION_TYPES.GET_DEFAULT_DATA_ANALYSES_TRANSACTION,
    payload: ids,
  };
};

export const clearDefaultDataAnalysesTransactionError = async () => {
  return {
    type: DATA_ANALYSIS_ACTION_TYPES.GET_DEFAULT_DATA_ANALYSES_TRANSACTION,
  };
};
