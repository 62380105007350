export function isValidDataAnalysisNameString(str) {
  const regexPattern = /^(\d+)_p[1-9]\d*(?:_(?:f[1-9]\d*)?)*-.*$/;
  return regexPattern.test(str);
}

export function extractProjectsAndFormsStringMatchingPart(str) {
  const regexPattern = /p[1-9]\d*(?:_(?:f[1-9]\d*)?)*/;
  const match = str.match(regexPattern);
  return match ? match[0] : null; // Returns the matched substring or null if no match found
}

export function extractAfterHyphen(str) {
  const regexPattern = new RegExp(/(?<=[-]).+$/);
  const match = str.match(regexPattern);
  return match ? match[0] : null; // Returns the matched substring or null if no match found
}

export function extractProjectFormsAndName(str) {
  if (!isValidDataAnalysisNameString(str)) return str;

  const regexPattern = /(?<=[_])p[1-9]\d*(?:_(?:f[1-9]\d*)?)*-.*$/;
  const match = str.match(regexPattern);
  return match ? match[0] : null; // Returns the matched substring or null if no match found
}

/**
 * This constant is the maximum number of character taken from the
 * variables alias in defaultDataAnalyses.
 * IMPORTANT: It must have the same value as the same variable in
 * service DefaultDataAnalysesService in backend.
 */
export const maxAliasLength = 20;

/**
 * This function is equivalent to the method actualVariablesAlias in service
 * DefaultDataAnalysesService in backend.
 * It is used for compatiblity with the alias values, to adapt it for alias
 * with length greater than maxAliasLength.
 * The value of maxAliasLength used is taken from the closure of the
 * function.
 * @param {string} alias
 * @returns
 */
export const getActualVariablesAlias = (alias) => {
  if (typeof alias !== "string") {
    const msg =
      "Unexpected error: value of argument 'alias' is not a string " +
      "in function 'getActualVariablesAlias'";
    console.log(msg);
    throw new Error(msg);
  }

  if (alias.length <= maxAliasLength) {
    return alias;
  } else {
    return `${alias.substring(0, maxAliasLength)}...`;
  }
};
