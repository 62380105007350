import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useIntl } from "react-intl";

const LoadingSpinWithMessage = ({
  customMessageId,
  showMessage = true,
  spinSize = "default",
  color = "gray"
}) => {
  const intl = useIntl();
  
  // Format the default message using the determined language
  const formattedDefaultMessage = intl.formatMessage(
    {
      id: "LoadingSpinWithMessage.defaultMessageId",
    },
    {
      defaultMessage: "Loading ...",     
    }
  );

  // Determine the size of the spin
  const determineSpinSize = () => {
    switch (spinSize.toLocaleLowerCase()) {
      case "small":
        return "small";
      case "large":
        return "large";
      default:
        return "default";
    }
  };

  return (
    <span>
      <Spin
        indicator={
          <LoadingOutlined style={{ fontSize: "1em", color: color }} spin />
        }
        size={determineSpinSize()}
      />
      {showMessage && (
        <span style={{ marginLeft: "10px" }}>
        {customMessageId
          ? intl.formatMessage({
              id: customMessageId,
              defaultMessage: "Loading ...",
            })
          : formattedDefaultMessage}
      </span>
      )}      
    </span>
  );
};

export default LoadingSpinWithMessage;
