import { ExclamationCircleFilled } from "@ant-design/icons";
import { Button, message } from "antd";
import React, { useRef, useEffect } from "react";
import { useIntl } from "react-intl";
import { filterUniqueObjects, findUniqueObjects } from "../utils/utils";

export const ShowMessages = (props) => {
  const { isDataPlotted, messagesList } = props;

  const intl = useIntl();
  const buttonRef = useRef(null);
  const lastMessagesListRef = useRef([]);

  const getMessagesToShow = (newUniqueMessagesList) => {
    return (
      <div
        style={{ textAlign: "left" }}
        id={"messagesToShow"}
      >
        {newUniqueMessagesList
          .filter((messageObj) => !!messageObj.content)
          .map((messageObj, i) => {
            return (
              <p key={i}>
                <span style={{ fontWeight: "bold", marginBottom: "0" }}>
                  {messageObj.title}
                </span>
                <span style={{ marginLeft: "0.5em" }}>
                  {messageObj.content}
                </span>
              </p>
            );
          })}
      </div>
    );
  };

  const showMessages = (newUniqueMessagesList) => {
    message.warning({
      content: getMessagesToShow(newUniqueMessagesList),
      style: { width: "85%", marginLeft: "auto", marginRight: "auto" },
    });
  };

  const getNewUniqueMessagesList = () => {
    let newUniqueMessagesList;
    if (lastMessagesListRef.current?.length > 0) {
      newUniqueMessagesList = findUniqueObjects(
        lastMessagesListRef.current,
        filterUniqueObjects(messagesList)
      );
    } else {
      newUniqueMessagesList = filterUniqueObjects(messagesList);
    }

    lastMessagesListRef.current = newUniqueMessagesList;
    return newUniqueMessagesList;
  };

  useEffect(() => {
    buttonRef.current.title = intl.formatMessage({
      id: "ShowMessages.button.title",
    });

    //eslint-disable-next-line
  }, []);

  useEffect(
    function ue_update_buttonRef() {
      if (isDataPlotted && messagesList?.length > 0) {
        const newUniqueMessagesList = getNewUniqueMessagesList();
        buttonRef.current.style.display = "inline";
        if (newUniqueMessagesList?.length > 0) {
          showMessages(newUniqueMessagesList);
        } else {
          //it will display the message if no other message is currently displayed!
          buttonRef.current.click();
        }
      } else {
        buttonRef.current.style.display = "none";        
      }
    },
    //eslint-disable-next-line
    [isDataPlotted, messagesList]
  );

  const handleClick = () => {
    const messageElement = document.getElementById("messagesToShow");
    if (messageElement !== null) return; //if message is currently being shown, then quit handler!!

    showMessages(lastMessagesListRef.current);
  };
  //***************************
  //end prompting missing data
  //***************************

  //jxs
  return (
    <Button
      type={"text"}
      ref={buttonRef}
      style={{ float: "right", width: "10px", display: "none", zIndex: 200 }}
      onClick={handleClick}
    >
      <ExclamationCircleFilled
        style={{
          color: "orange",
          position: "absolute",
          right: "10%",
          left: "10%",
          top: "27%",
        }}
      />
    </Button>
  );
};
